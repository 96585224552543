<template>
  <div id="root" :style="{ 'background-color': bss.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px', 'overflow-x': 'hidden'}">

    <link v-if="payment_settings.payment_gateway_id === 5" id="yappyStyle" rel="stylesheet" href="https://pagosbg.bgeneral.com/assets/css/styles.css" />
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + bss.font_family">
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + bss.font_family_card">
    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <not-found-page v-show="notFound"></not-found-page>
<!--    <div class="row justify-content-end text-right mt-3">-->
<!--      <div class="col-12">-->
<!--        <base-button :style="{'font-family': bss.font_family, 'color': bss.text_color}" v-if="!is_authenticated" link type="success" v-on:click="login()"><i class="tim-icons icon-single-02"></i> Login</base-button>-->
<!--        <base-button :style="{'font-family': bss.font_family, 'color': bss.text_color}" v-if="!is_authenticated" link type="success" v-on:click="register()"><i class="tim-icons icon-laptop"></i> Register</base-button>-->
<!--        <base-button :style="{'font-family': bss.font_family, 'color': bss.text_color}" v-if="is_authenticated" link type="success" v-on:click="toProfile()" class="mr-3"><i class="tim-icons icon-single-02"></i> Account</base-button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">

        <simple-wizard :text-color="bss.text_color" :backgroud-color="bss.button_color" ref="wiz">
          <template slot="header">
            <div class="mt-5">
              <img v-if="bss.show_logo" :height="bss.logo_height" :width="bss.logo_width" :style="{'object-fit': bss.logo_fit}" :src="bss.logo" alt="logo">
              <h3 :style="{'font-family': bss.font_family, 'color': bss.text_color}" class="card-title mt-3">{{ bss.title }}</h3>
              <p :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{bss.subtext}}</p>
            </div>
          </template>
          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label" >
              <i class="tim-icons icon-components"></i>
              <p class="d-sm-block d-none" v-if="bss.display_section_titles" :style="{'font-family': bss.font_family, 'color': bss.text_color}">
                {{ $t('book.headers.selectExperience') }}
              </p>
            </template>
            <div class="row justify-content-center" v-if="show_calendar">
              <div class="col-lg-8 col-10">
                <h2 :style="{'font-family': bss.font_family, 'color': bss.text_color}">
                  <base-button
                    :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'font-size': 'large', 'font-weight': 'bold'}"
                    v-on:click="show_calendar = false" link >
                    <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                  </base-button>
                  {{selectedGame.game_name}}
                </h2>
              </div>
            </div>
            <div class="row justify-content-center mb-4" v-if="!gift_card_link && games.length > 0">
              <a :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }"
                 v-if="showPrevious(currentDay) && show_calendar && !isMobile" v-on:click="decrementWeek(currentDay)" class="btn btn-link btn-primary d-none d-sm-block">
                <i class="tim-icons icon-minimal-left"></i> {{ $t('book.buttons.previousWeek') }}
              </a>
                <a :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }" v-if="showPrevious(currentDay) && (!show_calendar || isMobile)" v-on:click="decrementDay(currentDay)" class="btn btn-link btn-primary d-none d-sm-block">
                  <i class="tim-icons icon-minimal-left"></i>  {{ $t('book.buttons.previousDay') }}
                </a>
              <a :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }" v-if="showPrevious(currentDay) && (show_calendar || isMobile)" v-on:click="decrementDay(currentDay)" class="btn btn-link btn-primary d-block d-sm-none">
                <i class="tim-icons icon-minimal-left"></i>
              </a>
              <p :style="{'color': 'gray', 'font-size': 'medium', 'margin-top': '25px' }" v-if="!showPrevious(currentDay) && (!show_calendar || isMobile)" class="btn btn-link btn-primary d-none d-sm-block">
                <i class="tim-icons icon-minimal-left"></i> {{ $t('book.buttons.previousDay') }}
              </p>
              <p :style="{'color': 'gray', 'font-size': 'medium', 'margin-top': '25px' }" v-if="!showPrevious(currentDay)" class="btn btn-link btn-primary d-block d-sm-none ">
                <i class="tim-icons icon-minimal-left"></i>
              </p>
              <div class="col-7 col-lg-2">
                <div class="row justify-content-center mb-2">
                  <div class="col text-center ">
                    <p :style="{'color': bss.text_color }" class="text-uppercase">{{ $t('book.buttons.chooseDate') }}</p>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col">
                    <el-date-picker
                      class="custom-cursor"
                      size="small"
                      prefix-icon="' '"
                      ref="datePickerRef"
                      type="date"
                      :placeholder="$t('book.placeholders.selectDate')"
                      value-format="yyyy-MM-dd"
                      :format="day_of_week + ' ' + locale.date_format "
                      v-model="currentDay"
                      @change="getSchedules()"
                      :picker-options="{ disabledDate: (time) => disabledDates(time, currentDay) }"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <a v-if="show_calendar && !isMobile"
                 :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }" v-on:click="incrementWeek(currentDay)" class="btn btn-link btn-primary d-none d-sm-block">
                {{ $t('book.buttons.nextWeek') }} <i class="tim-icons icon-minimal-right"></i>
              </a>
              <a v-if="!show_calendar || isMobile" :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }" v-on:click="incrementDay(currentDay)" class="btn btn-link btn-primary d-none d-sm-block">
                {{ $t('book.buttons.nextDay') }} <i class="tim-icons icon-minimal-right"></i>
              </a>
              <a :style="{'color': bss.text_color, 'font-size': 'medium', 'margin-top': '25px' }" v-on:click="incrementDay(currentDay)" class="btn btn-link btn-primary d-block d-sm-none">
               <i class="tim-icons icon-minimal-right"></i>
              </a>
            </div>
            <div v-if="!show_calendar" class="row justify-content-center">
              <div v-if="!gift_card_link" style="display: inline-flex;" class="col-lg-4 col-md-6 col-sm-10 col-10" v-for="game in games" :key="game.id">
                <card :style="{ 'background-color': bss.card_color}">
                  <div slot="image" style="padding-bottom: 0; height: 250px">
                    <el-carousel :arrow="getArrowSetting(JSON.parse(game.game_images))" :autoplay="!!(bss.allow_autoscroll)" indicator-position="none" :interval="5000">
                      <el-carousel-item style="border-radius: 5px; width: 100%; height: 250px;"
                                        :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(game.game_images)"
                                        :key="'CS' + index">
                        <div  style="border-radius: 5px; width: 100%; height: 250px; position: relative">
                          <img id="img"
                               :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                               class="card-img"
                               style="position: absolute; transform-origin: center center; will-change: transform"
                               slot="image" :src="item.url"  alt="Card image cap"/>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>

<!--                  <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>-->
<!--                  <p class="address-overlay"><i class="tim-icons icon-square-pin"></i> <span class="small"> {{game.address}}, {{game.city}}, {{game.state}} {{game.zip}}</span></p>-->
                  <div class="row" v-if="bss.show_game_title">
                    <div class="col" v-if="bss.show_game_details_over_image" style="z-index: 100" :style="{'position': 'relative', 'height': '10px'}">
                      <h2 :style="{'position': 'absolute',
                      'bottom': '0', 'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold',
                          'margin-top': '-250px', 'margin-bottom': '100px'}">{{game.game_name}}</h2>
                    </div>
                    <div class="col" v-if="!bss.show_game_details_over_image">
                      <h2 :style="{ 'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{game.game_name}}</h2>
                    </div>
                  </div>
                  <div v-if="bss.show_game_details_over_image" style="z-index: 100"  :style="{'position': 'relative', 'height': '10px', 'font-family': bss.font_family_card, 'color': bss.text_color_card,
                          'margin-top': '-90px', 'margin-bottom': '50px'}">
                    <span class="mr-3" style="display: inline-block;" v-if="bss.show_number_of_players" :style="{'font-size': 'medium', 'font-weight': 'bold'}">
                      <i class="tim-icons el-icon-user-solid" v-show="game.min_players_count != null"></i> {{game.min_players_count}}
                      <span v-show="game.max_players_count != null"> - </span> {{game.max_players_count}}
                    </span>
                    <span class="mr-3" style="display: inline-block;" v-if="game.include_game_difficulty && bss.show_game_difficulty && game.difficulty_level < 6">
                      <b-form-rating style="margin-top: -7px; display: inline; max-width: 200px"
                                     :icon-empty="game.difficulty_icon"
                                     :icon-full="game.difficulty_icon + '-fill'"
                                     :color="game.difficulty_color"
                                     no-border
                                     readonly
                                     v-model="game.difficulty_level">
                      </b-form-rating>
                    </span>
                    <span style="display: inline-block;" v-if="bss.show_game_duration && game.duration_minutes" :style="{'font-size': 'medium', 'font-weight': 'bold'}">
                      <i class="tim-icons el-icon-alarm-clock"></i> {{game.duration_minutes}}
                      <span>{{ $t('book.minutes') }}</span>
                    </span>
                  </div>

                  <div class="mb-2" v-if="!bss.show_game_details_over_image" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'margin-top': '-20px' }">
                    <span class="mr-3" style="display: inline-block;" v-if="bss.show_number_of_players" :style="{'font-size': 'medium', 'font-weight': 'bold'}">
                      <i class="tim-icons el-icon-user-solid" v-show="game.min_players_count != null"></i> {{game.min_players_count}}
                      <span v-show="game.max_players_count != null"> - </span> {{game.max_players_count}}
                    </span>
                    <span class="mr-3" style="display: inline-block;" v-if="game.include_game_difficulty && bss.show_game_difficulty && game.difficulty_level < 6">
                      <b-form-rating style="margin-top: -7px; display: inline; max-width: 200px"
                                     :icon-empty="game.difficulty_icon"
                                     :icon-full="game.difficulty_icon + '-fill'"
                                     :color="game.difficulty_color"
                                     no-border
                                     readonly
                                     v-model="game.difficulty_level">
                      </b-form-rating>
                    </span>
                    <span style="display: inline-block;" v-if="bss.show_game_duration && game.duration_minutes" :style="{'font-size': 'medium', 'font-weight': 'bold'}">
                      <i class="tim-icons el-icon-alarm-clock"></i> {{game.duration_minutes}}
                      <span>{{ $t('book.minutes') }}</span>
                    </span>
                  </div>
                  <!--   No data panel -->
                  <div v-if="getOrderedTimeSlots(game.slots).length <= 0 || getOrderedTimeSlots(game.slots)[0].remove">
                    <div class="empty-bookings" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,
                                  'color': bss.button_color }">
                        <span style="font-size: 2.1em">
                          <i class="tim-icons icon-calendar-60"></i>
                        </span>
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="mt-3">{{ $t('book.noBookings') }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div style="display: inline-flex;" class="col-6 col-sm-6 col-lg-4 col-md-6" v-for="(s, i) in getOrderedTimeSlots(game.slots)" :key="'S' + i" :ref="'el'+i">
                      <base-button  style="overflow-wrap: anywhere" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,
                                  'color': bss.button_color, 'width': '100%', 'padding-x': '5px', 'padding-left': '3px',  'padding-right': '3px' }"
                                    v-on:click="tabOne(1, game, s)"
                                   v-if="(!s.block && !s.remove) && (s.status === 'available' || s.status === 'checked')"
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}
                      </base-button>
                      <base-button style="overflow-wrap: anywhere" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color, 'padding-top': '6px', 'padding-bottom': '6px',
                                  'color': bss.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   v-on:click="tabOne(1, game, s)"
                                   v-if="(s.status === 'booked')"
                                   disabled
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}<br/>
                        {{bss.booked_text}}
                      </base-button>
                      <base-button style="overflow-wrap: anywhere" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color, 'padding-top': '6px', 'padding-bottom': '6px',
                                  'color': bss.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   v-on:click="tabOne(1, game, s)"
                                   v-if="(s.status === 'blocked')"
                                   disabled
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}<br/>
                        {{bss.blocked_text}}
                      </base-button>
                      <base-button style="overflow-wrap: anywhere"
                        :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color, 'padding-top': '6px', 'padding-bottom': '6px',
                                  'color': bss.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   v-on:click="tabOne(1, game, s)"
                                   v-if="((s.status === 'call_to_book' && !s.remove) || (s.block && !s.remove))
                                   && !s.has_external_link && s.slot_text && !(s.status === 'booked' || s.status === 'blocked')"
                                   disabled
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}<br/>
                        {{ s.slot_text }}
                      </base-button>
                      <base-button style="overflow-wrap: anywhere"
                                   :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color, 'padding-top': '6px', 'padding-bottom': '6px',
                                  'color': bss.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   v-on:click="tabOne(1, game, s)"
                                   v-if="(s.status === 'call_to_book' || s.block)
                                   && !s.has_external_link && !s.slot_text && !(s.status === 'booked' || s.status === 'blocked')"
                                   disabled
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}<br/>
                        {{ bss.call_to_book_text }}
                      </base-button>
                      <base-button style="overflow-wrap: anywhere" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,  'padding-top': '6px', 'padding-bottom': '6px',
                                  'color': bss.button_color, 'width': '100%',
                                  'padding-left': '3px',  'padding-right': '3px' }"
                                   v-on:click="goToCallToBook(s)"
                                   v-if="(s.status === 'call_to_book' || s.block) && s.has_external_link && !(s.status === 'booked' || s.status === 'blocked')"
                                   simple >
                        {{getTime(locale.time_format, s.start_time)}}<br/>
                        {{ s.slot_text }}
                      </base-button>
                    </div>
                  </div>
                  <div class="row justify-content-center" v-if="getOrderedTimeSlots(game.slots).length > 0" v-for="(r) in rowsToAdd(game)" :key="'A' + r">
                    <p :style="{'height': time_slot_height}"> </p>
                  </div>

                  <div class="row justify-content-center mt-2" style="margin-bottom: -10px" v-if="bss.show_calendar_link">
                    <div class="col text-center">
                      <base-button :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" link v-on:click="showCalendar(game)">
                        {{ $t('book.buttons.seeMoreTimes') }} >
                      </base-button>
                    </div>
                  </div>
                </card>

              </div>

              <div v-if="is_gift_card" style="display: inline-flex;" class="col-lg-4 col-md-6 col-sm-10 col-10">
                <card :style="{ 'background-color': bss.card_color}">
                  <img slot="image" class="card-img-top fixed-size-img" :src="payment_settings.url"  alt="Card image cap"/>

                  <div class="row" style="margin-top: -15px">
                    <div class="col" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,
                                  'color': bss.button_color }">
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                        {{ $tc('book.giftCard', 2) }}
                      </h4>
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.giftCardDesc') }}</p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col" :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,
                                  'color': bss.button_color }">
                      <base-button :style="{ 'font-family': bss.font_family_card,
                                  'border-color': bss.button_color,
                                  'color': bss.button_color, 'width': '100%', 'padding-left': '3px',  'padding-right': '3px' }"
                                   simple v-on:click="giftCardSelect()">
                        {{ $t('book.buttons.purchaseGiftCards') }}
                      </base-button>
                    </div>
                  </div>
                </card>

              </div>
            </div>

            <div class="row justify-content-center" v-if="show_calendar">
              <div class="col-lg-8 col-10">
                <fullCalendar ref="calendar" :options="calendarOptions" :hooks="calendarHooks"/>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
<!--              <span :style="{'color': bss.text_color_card, 'font-weight': 'bold', 'font-size': 'large'}">2</span>-->
              <i class="tim-icons icon-calendar-60"></i>
              <p class="d-sm-block d-none" v-if="bss.display_section_titles" :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{ $t('book.bookingDetails') }}</p>
            </template>
            <div class="row justify-content-center" v-if="gift_card_select">
              <div class="col-11 col-lg-6">
                <card :style="{ 'background-color': bss.card_color}">
                  <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                      <el-carousel v-on:change="setGiftCardDesign" :autoplay="false" indicator-position="outside">
                        <el-carousel-item v-for="(item, index) in gift_card_designs" :key="'CS' + index">
                          <img style="border: 3px solid white; object-fit: contain; width: 600px; height: 300px" :src="item.url">
                        </el-carousel-item>
                      </el-carousel>
                    </div>
                  </div>

                  <div class="row justify-content-between">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title text-uppercase">{{ $t('book.giftCardValue') }}</p>
                    </div>
                  </div>
                  <div class="row " :key="gc_key">
                    <div class="col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.value') }}</label>
                      <base-input :secondary-color="bss.text_color_card">
                        <base-dropdown :style="{'font-family': bss.font_family_card }"
                                       title-classes="form-group form-control" :text-color="bss.button_color"
                                       :font-family="bss.font_family_card"
                                       :title="getGiftCardAmount(selected_gift_card_amount.amount)"
                        >
                          <a v-for="(d, i) in gift_card_amounts"  :style="{'font-family': bss.font_family_card }"
                             style="white-space: normal; overflow-wrap: break-word;"
                             class="dropdown-item" href="#" :key="'D' + i" v-on:click="getGiftCardTotal('dropdown', d)">
                            {{ getGiftCardAmount(d.amount) }}</a>
                        </base-dropdown>
                      </base-input>
                    </div>
                    <div class="col-lg-6" v-if="selected_gift_card_amount.id === 0">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.customValue') }}
                        {{getCurrencySymbol(locale.currency)}}{{getAmount(gift_card_settings.gift_cards_min_purchase_value)}} {{getCurrencyCode(locale.currency)}} -
                        {{getCurrencySymbol(locale.currency)}}{{getAmount(gift_card_settings.gift_cards_max_purchase_value)}} {{getCurrencyCode(locale.currency)}}
                      </label>
                      <base-input :secondary-color="bss.text_color_card" v-on:change="getGiftCardTotal('custom')" v-model="gift_card.custom_amount"
                        :error="errors.gift_card.custom_amount" :input-style="{'color': bss.text_color_card}"
                                  :placeholder="getCurrencySymbol(locale.currency) + getAmount(gift_card_settings.gift_cards_min_purchase_value) + getCurrencyCode(locale.currency) +
                                         ' - ' + getCurrencySymbol(locale.currency) + getAmount(gift_card_settings.gift_cards_max_purchase_value) + getCurrencyCode(locale.currency)">
                      </base-input>
                    </div>
                  </div>
                  <div class="row"  >
                    <div class="col-lg-6 mt-2" v-if="selected_gift_card_amount.id === 1">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.game') }}</label>
                      <base-input :secondary-color="bss.text_color_card" v-if="gift_card_amounts.length > 0">
                        <base-dropdown :style="{'font-family': bss.font_family_card }"
                                       title-classes="form-group form-control" :text-color="bss.button_color"
                                       :font-family="bss.font_family_card"
                                       :title="selectedGame.game_name"
                        >
                          <a v-for="(g, i) in games"  :style="{'font-family': bss.font_family_card }"
                             style="white-space: normal; overflow-wrap: break-word;"
                             class="dropdown-item" href="#" :key="'D' + i" v-on:click="getGiftCardTotal('game', selected_gift_card_amount, g)">
                            {{ g.game_name }}</a>
                        </base-dropdown>
                      </base-input>
                    </div>
                    <div class="col-lg-4" v-if="selected_gift_card_amount.id === 1" :key="gc_key">
                      <div v-for="(c, i) in pricing_categories" :key="'C' + i">
                        <label class="mt-2 text-uppercase" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{c.category}}</label>
                        <div class="el-input-number" >
                          <span role="button" class="el-input-number__decrease" v-on:click="decrementCounter(c, 'gc')"
                                :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}">
                            <i class="el-icon-minus"></i>
                          </span>
                          <span role="button" v-on:click="incrementCounter(c, 'gc')"
                                :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}"
                                class="el-input-number__increase">
                            <i class="el-icon-plus"></i>
                          </span>
                          <div class="el-input">
                            <input disabled :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color,  'background-color': 'inherit'}"
                                   v-model="c.number_of_players"
                                   type="text" autocomplete="off" max="4" min="0" class="el-input__inner" role="spinbutton" aria-disabled="false">
                          </div>
                        </div>

<!--                        <el-input-number v-model="c.number_of_players" :min="c.minimum_participants" :max="selectedGame.max_players_count"-->
<!--                                         @change="getGiftCardTotal('players', c)" />-->
                      </div>
                    </div>
                  </div>
                  <label class="d-block d-sm-none" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.quantity') }}</label>
                  <div class="row">
                    <div class="col-lg-4 col-12" v-if="gift_card_settings.gift_cards_allow_bulk_purchase">
                      <label class="d-none d-sm-block" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.quantity') }}</label>
                      <div class="el-input-number" >
                          <span role="button" class="el-input-number__decrease" v-on:click="decrementCounterGCQuantity()"
                                :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}">
                            <i class="el-icon-minus"></i>
                          </span>
                        <span role="button" v-on:click="incrementCounterGCQuantity()"
                              :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}"
                              class="el-input-number__increase">
                            <i class="el-icon-plus"></i>
                          </span>
                        <div class="el-input">
                          <input disabled :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color,  'background-color': 'inherit'}"
                                 v-model="gift_card.quantity"
                                 type="text" autocomplete="off" max="4" min="0" class="el-input__inner" role="spinbutton" aria-disabled="false">
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-8">
                      <div class="mt-2 d-block d-sm-none"></div>
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.totalValue') }}</label>
                      <base-input :secondary-color="bss.text_color_card">
                        <h1 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                          {{getCurrencySymbol(locale.currency)}}{{getAmount(gift_card.total)}}{{getCurrencyCode(locale.currency)}}
                        </h1>
                      </base-input>
                    </div>
                  </div>


                  <div class="row justify-content-between">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title text-uppercase">{{ $t('book.deliveryDetails') }}</p>
                    </div>
                  </div>

                  <div class="row mb-3" >
                    <div class="col">
                      <div
                        class="btn-group btn-group-toggle"
                        :class="'float-left'"
                        data-toggle="buttons"
                      >
                        <label
                          class="btn btn-sm btn-primary btn-simple"
                          :style="buttonGroupStyle[0]"
                        >
                          <input
                            type="radio"
                            @click="selectGiftCardDeliveryMethod(1)"
                            name="options"
                            autocomplete="off"
                            :checked="gift_card.send_instantly"
                          />
                          <span style="font-size: medium; font-weight: lighter">{{ $t('book.sendInstantly') }}</span>
                        </label>
                        <label
                          class="btn btn-sm btn-primary btn-simple"
                          :style="buttonGroupStyle[1]" >
                          <input
                            type="radio"
                            @click="selectGiftCardDeliveryMethod(0)"
                            name="options"
                            autocomplete="off"
                            :checked="!gift_card.send_instantly"
                          />
                          <span style="font-size: medium; font-weight: lighter">{{ $t('book.sendInFuture') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2 justify-content-between" v-if="!gift_card.send_instantly">
                    <div class="col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.deliveryDate') }}</label>

                      <base-input :secondary-color="bss.text_color_card">
                        <el-date-picker
                          class="custom-cursor"
                          prefix-icon="' '"
                          type="date"
                          placeholder="Select date"
                          value-format="yyyy-MM-dd"
                          :format="day_of_week + ' ' + locale.date_format "
                          v-model="gift_card.delivery_date"
                          :picker-options="{ disabledDate: (time) => disabledDates(time, gift_card.delivery_date) }"
                        >
                        </el-date-picker>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col">
                      <base-checkbox :border-color="bss.button_color" :check-mark-color="bss.card_color" :accent-color="bss.button_color" v-model="gift_card.send_to_self">
                        <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ $t('book.sendToSelf') }}</p></base-checkbox>
                    </div>
                  </div>
                  <div class="row justify-content-center" v-if="!gift_card.send_to_self">
                    <div class="col-12 col-lg-6">
                      <base-input :secondary-color="bss.text_color_card" :error="errors.gift_card.first_name"
                                  :input-style="{'color': bss.text_color_card}"
                                  v-model="gift_card.recipient_first_name" :placeholder="$t('book.placeholders.recipientFirstName')"></base-input>
                    </div>
                    <div class="col-12 col-lg-6" >
                      <base-input :secondary-color="bss.text_color_card" :error="errors.gift_card.last_name" :input-style="{'color': bss.text_color_card}" v-model="gift_card.recipient_last_name"
                                  :placeholder="$t('book.placeholders.recipientLastName')"></base-input>
                    </div>
                  </div>
                  <div class="row justify-content-center" v-if="!gift_card.send_to_self">
                    <div class="col">
                      <base-input :secondary-color="bss.text_color_card" :error="errors.gift_card.email" :input-style="{'color': bss.text_color_card}"
                                  v-model="gift_card.recipient_email" :placeholder="$t('book.placeholders.recipientEmail')"></base-input>
                    </div>
                  </div>

                  <div class="row mt-2 justify-content-between">
                    <div class="col">
                      <p class="card-title text-uppercase" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.personalMessage')}}</p>
                    </div>
                  </div>

                  <div class="row  justify-content-center">
                    <div class="col">
                      <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}">
                        <textarea :style="{'color': bss.text_color_card}" class="form-control" :placeholder="$t('book.placeholders.addPersonalMessage')" v-model="gift_card.personal_message" rows="2"></textarea>
                      </base-input>
                    </div>
                  </div>

                  <div class="row mt-2 justify-content-between" v-if="gift_card_settings.gift_cards_include_policy_section">
                    <div class="col">
                      <p class="card-title text-uppercase" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                        {{gift_card_settings.gift_cards_policy_section_title}}
                      </p>
                    </div>
                  </div>
                  <div class="row justify-content-center" v-if="gift_card_settings.gift_cards_include_policy_section">
                    <div class="col">
                      <small v-html="gift_card_settings.gift_cards_redemption_policy" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{gift_card_settings.gift_cards_redemption_policy}}</small>
                    </div>
                  </div>

                  <div :style="{ 'color': bss.text_color_card}" class="row mt-2" v-if="gift_card_settings.gift_cards_require_terms">
                    <div class="col">
                      <base-checkbox :border-color="bss.button_color" :check-mark-color="bss.card_color" :accent-color="bss.button_color" v-model="gift_card.terms" :error="errors.gift_card.terms">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.iAgreeTo')}}
                           <a :style="{'font-family': bss.font_family_card, 'color': bss.link_color}" v-if="gift_card_settings.gift_cards_include_tos_link"
                              :href="gift_card_settings.external_tos_link" target="_blank">{{ gift_card_settings.gift_cards_tos_text }}</a>
                          <span v-else>{{ gift_card_settings.gift_cards_tos_text }}</span>
                          </span>
                      </base-checkbox>
                      <p :style="{'font-family': bss.font_family_card, 'color': '#ec250d'}" class="small ml-4" v-if="errors.gift_card.terms">{{$t('book.acceptToS')}}</p>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-lg-1 col-2">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                        v-on:click="tabZero(0, 1)" link >
                        <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                      </base-button>
                    </div>
                    <div class="col-lg-11 col-10">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"
                        v-on:click="setGiftCard()" simple >{{$t('book.buttons.continue')}}</base-button>
                    </div>
                  </div>

                </card>
              </div>
            </div>
            <div class="row justify-content-center" v-if="!gift_card_select">
              <div class="col-11 col-lg-6">
                <card :style="{ 'background-color': bss.card_color}">
                  <div v-if="selectedGame.hasOwnProperty('game_images')" slot="image" class="card-img-top" style="padding-bottom: 0; height: 300px">
                    <el-carousel :arrow="getArrowSetting(JSON.parse(selectedGame.game_images))" :autoplay="!!bss.allow_autoscroll" indicator-position="none" :interval="5000">
                      <el-carousel-item style="border-radius: 5px; width: 100%; height: 300px;"
                                        :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(selectedGame.game_images)"
                                        :key="'CS' + index">
                        <div  style="border-radius: 5px; width: 100%; height: 300px; position: relative">
                          <img
                               :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                               class="card-img"
                               style="position: absolute; transform-origin: center center; will-change: transform"
                               slot="image" :src="item.url"  alt="Card image cap"/>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <div class="row">
                    <div class="col">
                      <h3 v-if="bss.show_game_title" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{selectedGame.game_name}}</h3>
<!--                      <h5 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" v-if="bss.show_game_duration">-->
<!--                        <i class="tim-icons icon-time-alarm"></i>&nbsp; &nbsp;{{selectedGame.duration_minutes}} {{$t('book.minutes')}}-->
<!--                      </h5>-->
                      <p v-html="selectedGame.game_description" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" ></p>

<!--                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="mt-3"-->
<!--                         v-show="bss.show_number_of_players && selectedGame.min_players_count != null">{{$t('book.players')}}: &nbsp; &nbsp;{{selectedGame.min_players_count}}-->
<!--                        <span v-show="selectedGame.max_players_count != null"> - </span> {{selectedGame.max_players_count}}-->
<!--                      </p>-->

<!--                      <p v-if="bss.show_game_difficulty && selectedGame.include_game_difficulty && selectedGame.difficulty_level <6" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="d-inline-flex">-->
<!--                        {{ selectedGame.difficulty_label }}: &nbsp; &nbsp;-->
<!--                        <b-form-rating style="margin-top: -7px"-->
<!--                                       :icon-empty="selectedGame.difficulty_icon"-->
<!--                                       :icon-full="selectedGame.difficulty_icon + '-fill'"-->
<!--                                       :color="selectedGame.difficulty_color"-->
<!--                                       no-border-->
<!--                                       readonly-->
<!--                                       v-model="selectedGame.difficulty_level">-->

<!--                        </b-form-rating>&nbsp;-->
<!--                      </p>-->
                    </div>
                  </div>
                  <hr :style="{ 'background-color': bss.text_color_card}">
                  <div class="row">
                    <div class="col">

                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                        <i class="tim-icons icon-calendar-60"></i>&nbsp;{{getDayOfWeek(selectedBooking.booking_date)}} {{getFormattedDate(locale.date_format, selectedBooking.booking_date)}}
                        <i class="tim-icons icon-time-alarm"></i>&nbsp;{{getTime(locale.time_format, selectedBooking.start_time)}}&nbsp;
                        <base-button
                          :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                          size="sm"
                          v-on:click="tabZero(0)" simple>{{$t('book.buttons.changeDateTime')}}</base-button>
                      </h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" >
                      <div v-for="(c, i) in pricing_categories" :key="'C' + i">
                        <div class="row">
                          <div class="col">
                            <label class="mt-2 text-uppercase" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{c.category}}</label>
                          </div>
                        </div>
                        <div class="el-input-number" >
                          <span role="button" class="el-input-number__decrease" v-on:click="decrementCounter(c)"
                                :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}">
                            <i class="el-icon-minus"></i>
                          </span>
                          <span role="button" v-on:click="incrementCounter(c)"
                                :style="{ 'font-family': bss.font_family_card, 'border-radius' : '0px', 'border-color': bss.button_color, 'color': bss.card_color, 'background-color': bss.button_color}"
                                class="el-input-number__increase">
                            <i class="el-icon-plus"></i>
                          </span>
                          <div class="el-input">
                            <input disabled :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color,  'background-color': 'inherit'}"
                                   v-model="c.number_of_players"
                              type="text" autocomplete="off" max="4" min="0" class="el-input__inner" role="spinbutton" aria-disabled="false">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6 mt-2">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="text-uppercase">{{$t('book.subtotal')}}</p>
                      <h2 style="margin-bottom: 0px" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{ getNumberFormat(getAmount(subtotal)) }} {{getCurrencyCode(locale.currency)}}</h2>
                      <small v-if="!price_settings.hide_pricing_rate">
                        <div  v-for="(c, i) in pricing_categories" :key="'CC' + i">
                          <span  :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getRate(c)}}</span>
                        </div>
                      </small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-input :secondary-color="bss.text_color_card" :error="errors.number_of_players"><p></p></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-1 col-2">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                        v-on:click="tabZero(0, 1)" link >
                        <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                      </base-button>
                    </div>
                    <div class="col-lg-11 col-10">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"
                        v-on:click="tabTwo(2)" simple >{{$t('book.buttons.continue')}}</base-button>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
<!--              <span :style="{'color': bss.text_color_card, 'font-weight': 'bold', 'font-size': 'large'}">3</span>-->
              <i class="tim-icons icon-single-02"></i>
              <p class="d-sm-block d-none" v-if="bss.display_section_titles" :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{$t('book.yourInfo')}}</p>
            </template>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-10">
                <card :style="{ 'background-color': bss.card_color }">
                  <div class="row">
                    <div class="col">
                      <h3 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{$t('book.personalInfo')}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div :style="{ 'color': bss.text_color_card}" class="col-12 col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.placeholders.firstName')}}*</label>
                      <base-input v-on:change="addToCart(5)" :secondary-color="bss.text_color_card" :error="errors.first_name" :input-style="{'color': bss.text_color_card}" :placeholder="$t('book.placeholders.firstName')" v-model="user.first_name"></base-input>
                    </div>
                    <div :style="{ 'color': bss.text_color_card}" class="col-12 col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.placeholders.lastName')}}*</label>
                      <base-input v-on:change="addToCart(5)" :secondary-color="bss.text_color_card" :error="errors.last_name" :input-style="{'color': bss.text_color_card}" :placeholder="$t('book.placeholders.lastName')" v-model="user.last_name"></base-input>
                    </div>
                  </div>
                  <div :style="{ 'color': bss.text_color_card}" class="row">
                    <div class="col-12 col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.placeholders.email')}}*</label>
                      <base-input v-on:change="addToCart(5)" :secondary-color="bss.text_color_card" :error="errors.email" :input-style="{'color': bss.text_color_card}" placeholder="example@gmail.com" v-model="user.email" type="email"></base-input>
                    </div>
                    <div class="col-12 col-lg-6">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.placeholders.phone')}}*</label>
                      <base-input v-on:change="addToCart(5)" :secondary-color="bss.text_color_card" :error="errors.phone" :input-style="{'color': bss.text_color_card}" :placeholder="phone_placeholder" v-model="user.phone"></base-input>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="custom_fields.length <= 0">
                    <div class="col-lg-1 col-2">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                        v-on:click="tabOne(1)" link >
                        <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                      </base-button>
                    </div>
                    <div class="col-10 col-lg-11">
                      <base-button v-if="getTotalAmount() > 0 && !payOnArrivalRequired()"
                                   :style="{ 'width': '100%', 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                                   v-on:click="tabThree(3, 0)" simple >{{$t('book.buttons.continue')}}</base-button>
                      <base-button v-if="getTotalAmount() <= 0 || payOnArrivalRequired()" :disabled="loading"
                                   :style="{ 'width': '100%', 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                                   v-on:click="tabThree(4, 1)" simple >{{$t('book.buttons.completeBooking')}}</base-button>
                    </div>
                  </div>
                </card>
                <card :style="{ 'background-color': bss.card_color }" v-if="custom_fields.length > 0">
                  <div class="row">
                    <div class="col">
                      <h3 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{$t('book.additionalInfo')}}</h3>
                    </div>
                  </div>
                  <div :style="{ 'color': bss.text_color_card}" class="row" v-for="(cf, i) in custom_fields" :key="'CF' + i">
                    <div class="col mb-3" v-if="cf.type === 1 && isFieldVisibile(cf)">
                      <base-checkbox :border-color="bss.button_color" :accent-color="bss.button_color" :check-mark-color="bss.card_color"
                                     v-model="cf.customer_response"
                                     simple size="sm">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}</span>
                        <span v-for="(el, i) in JSON.parse(cf.external_links)" :key="'EL'+ i">
                              <a
                                    :style="{'font-family': bss.font_family_card, 'color': bss.link_color}" target="_blank"
                                    :href="el.link"> {{el.link_text}}</a>
                        </span>
                        <span v-for="(p, i) in JSON.parse(cf.policies)" :key="'PLI' + i">
                          <base-button
                                       :style="{'font-family': bss.font_family_card, 'color': bss.link_color}"
                                       style="font-weight:300; font-size: small; padding: 0"
                                     size="sm" link class="btn btn-link" href="" v-on:click="openPolicy(p, cf)">{{p.policy_link_text}}</base-button>
                        </span>
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" v-if="cf.required">*</span>
                      </base-checkbox>
                      <p :style="{'font-family': bss.font_family_card, 'color': '#ec250d'}" class="ml-4" v-if="cf.error">
                        {{ cf.error }}</p>
                    </div>
                    <div class="col mb-3" v-if="cf.type === 2 && isFieldVisibile(cf)">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}<span v-if="cf.required">*</span>
                      </label>
                      <base-input :secondary-color="bss.text_color_card" :error="cf.error" :input-style="{'color': bss.text_color_card}"
                                  v-model="cf.customer_response">

                      </base-input>
                    </div>
                    <div class="col mb-3" v-if="cf.type === 3 && isFieldVisibile(cf)">
                      <div class="row">
                        <div class="col-lg-6">
                          <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.label}}<span v-if="cf.required">*</span>
                          </label>
                          <base-input :secondary-color="bss.text_color_card" placeholder="Select">
                            <base-dropdown :key="'CFF' + j" :style="{'font-family': bss.font_family_card }"
                                           title-classes="form-group form-control" :text-color="bss.button_color"
                                           :font-family="bss.font_family_card"
                                           :title="getDropDownLabel(cf.customer_response)"

                            >
                              <a v-for="(c, i) in cf.dropdown_options"  :style="{'font-family': bss.font_family_card }"
                                 style="white-space: normal; overflow-wrap: break-word;"
                                 class="dropdown-item" href="#" :key="'DOJ' + i" v-on:click="selectDropdown(cf, c)">
                                {{ getDropDownLabel(c) }}</a>
                            </base-dropdown>
                          </base-input>
                        </div>

                        <div class="col" v-if="cf.customer_response">
                          <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{cf.customer_response.follow_up_field_label}}
                            <span v-if="cf.required && cf.customer_response.has_follow_up_field">*</span>
                          </label>
                          <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-model="cf.customer_response.follow_up_response"
                                      v-if="cf.customer_response && cf.customer_response.has_follow_up_field"></base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <modal :show.sync="modals.policy"
                         body-classes="p-0"
                         modal-classes="modal-dialog-centered modal-lg">
                    <card :style="{ 'background-color': bss.background_color}"
                          header-classes="bg-white pb-5"
                          class="border-0 mb-0">
                      <p :style="{'color': bss.text_color}" v-html="selectedPolicy.policy"></p>
                      <div class="row mt-3 justify-content-center">
                        <div class="col text-center">
                          <base-button
                            :style="{ 'width': '100%', 'font-family': bss.font_family_card,
                            'border-color': bss.text_color, 'color': bss.text_color }" simple
                                        v-on:click="agreeToPolicy()">{{$t('book.iAgree')}}</base-button>
                        </div>
                      </div>
                    </card>
                  </modal>
                  <div class="row mt-2">
                    <div class="col-lg-1 col-2">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                        v-on:click="tabOne(1)" link >
                        <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                      </base-button>
                    </div>
                    <div class="col-10 col-lg-11">
                      <base-button v-if="getTotalAmount() > 0 && !payOnArrivalRequired()"
                                   :style="{ 'width': '100%', 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                                   v-on:click="tabThree(3, 0)" simple >{{$t('book.buttons.continue')}}</base-button>
                      <base-button v-if="getTotalAmount() <= 0 || payOnArrivalRequired()" :disabled="loading"
                                   :style="{ 'width': '100%', 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"
                                   v-on:click="tabThree(4, 1)" simple >{{$t('book.buttons.completeBooking')}}</base-button>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-lg-3 col-10">
                <card :style="{ 'background-color': bss.card_color }">
                  <div class="row justify-content-center">
                    <base-button :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"  v-on:click="tabZero(0)" class="button-wide no-upper-margin" simple size="sm">
                      {{$t('book.buttons.addAnotherBooking')}}
                    </base-button>
                  </div>
                  <div class="row mt-3" v-if="cart.length > 0">
                    <div class="col-11">
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">
                        {{$t('book.yourPurchases')}}
                      <i class="tim-icons icon-cart"></i>
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="cart.length <= 0">
                    <div class="col ml-2 mr-2"
                         :style="{'border-radius': '.1875rem', 'min-height' : '100px', 'padding': '20px 0 15px', 'text-align': 'center', 'border': '1px solid ' + bss.button_color }">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: 2em">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                        {{$t('book.yourCartIsEmpty')}}</h4>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0">
                    <div class="col-12">
                      <div v-for="(c, index) in cart" :key="'M' + index">
                        <div v-if="c.hasOwnProperty('gift_card')">
                          <div class="row">
                            <div class="col-3">
                              <img class="game-img" style="object-fit: contain" :src="c.gift_card.design_url.url"/>
                            </div>
                            <div class="col-9">
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{$tc('book.giftCard', 1)}}
                                <base-button
                                  @click="removeBooking(index, c)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                              </p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.quantity')}}:</span> {{c.gift_card.quantity}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.giftCardValue')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(c.subtotal))}} {{getCurrencyCode(locale.currency)}}</p>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <div class="row">
                            <div class="col-3"
                                 v-if="c.game.hasOwnProperty('game_images') && c.game.game_images !== null && c.game.game_images.length > 0 ">
                              <img class="game-img" :src="JSON.parse(c.game.game_images)[0].url"/>
                            </div>
                            <div class="col-9">
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{c.game.game_name}}
                                <base-button
                                  @click="removeBooking(index, c)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                              </p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.date')}}:</span> {{getDayOfWeek(c.booking.booking_date)}} {{getFormattedDate(locale.date_format, c.booking.booking_date)}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.time')}}:</span> {{getTime(locale.time_format, c.booking.start_time)}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.quantity')}}:</span> {{c.quantity}}</p>
                              <p v-if="ppp" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.rate')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getAmount(ppp)}} {{getCurrencyCode(locale.currency)}} {{$t('book.perPerson')}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.bookingSubtotal')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(c.subtotal))}} {{getCurrencyCode(locale.currency)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="bss.show_promo_code_field === 1">
                    <div class="col">
                      <div style="display: flex; width: 100%;">
                        <base-input :secondary-color="bss.text_color_card" v-model="promo_code"
                                    style="flex-grow: 1; border-radius: 0px;"
                                    :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                                    :placeholder="bss.promo_code_placeholder_text">
                        </base-input>
                        <base-button simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                                     :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                                     v-on:click="applyPromo()">
                          {{$t('book.apply')}}
                        </base-button>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="payment_settings.allow_tipping
                  && ((payment_settings.allow_preset_tipping_options && tipping_options.length > 0)
                  || payment_settings.allow_custom_tipping_options)">
                    <div class="col">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{payment_settings.tip_label}}</label>
                      <base-input :secondary-color="bss.text_color_card" v-if="payment_settings.allow_preset_tipping_options && tipping_options.length > 0">
                        <base-dropdown :style="{'font-family': bss.font_family_card }"
                                       title-classes="form-group form-control" :text-color="bss.button_color"
                                       :font-family="bss.font_family_card"
                                       :title="tip_title"
                        >
                          <a v-for="(t, i) in tipping_options"  :style="{'font-family': bss.font_family_card }"
                             style="white-space: normal; overflow-wrap: break-word;"
                             class="dropdown-item" href="#" :key="'T' + i" v-on:click="computeTip(t)">
                            {{ getTipAmount(t) }}</a>
                        </base-dropdown>
                      </base-input>

                      <div style="display: flex; width: 100%;">
                        <base-input
                          v-if="(payment_settings.allow_custom_tipping_options && !payment_settings.allow_preset_tipping_options) || selectedCustomTipOption"
                                    :secondary-color="bss.text_color_card" v-model.number="applied_tip_amount"
                                    style="flex-grow: 1; border-radius: 0px;"
                                    :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                                    :placeholder="tip_placeholder">
                        </base-input>
                        <base-button v-if="(payment_settings.allow_custom_tipping_options && !payment_settings.allow_preset_tipping_options) || selectedCustomTipOption"
                                     simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                                     :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                                     v-on:click="computeTip({id: 0, name: $t('book.customAmount'), amount: applied_tip_amount, value_type: payment_settings.custom_tipping_value_type})">
                          {{$t('book.apply')}}
                        </base-button>
                      </div>
                    </div>
                  </div>

                  <hr :style="{ 'background-color': bss.text_color_card}" v-if="cart.length > 0">
                  <div class="row" v-if="cart.length > 0">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.subtotal')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getCartSubtotal()}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(t, i) in taxes" :key="'J' + i" v-if="cart.length > 0 && hasBookingsInCart()">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ t.name }}</p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getTax(t.amount, t.value_type, t.is_compound, t.apply_after_discount)))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>

                  <div class="row" v-for="(f, i) in fees" :key="'K' + i" v-if="cart.length > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ f.name }}</p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getFee(f.amount, f.value_type)))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && applied_tip > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.tip')}}
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(applied_tip))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>

                  <div class="row" v-for="(p, i) in promo_codes" :key="pc_key + i" v-if="cart.length > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ p.name }}
                        <!--                        <base-button :style="{'color': bss.text_color_card}" @click="removePromoCode(p)" style="padding: 0; margin: 0"  icon class="btn-link d-inline">-->
                        <i style="cursor: pointer" :style="{'color': bss.text_color_card}" @click="removePromoCode(p)"  class="tim-icons icon-simple-remove"></i>
                        <!--                        </base-button>-->
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">-{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(p.value_amount))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>

                  <hr :style="{ 'background-color': bss.text_color_card}" v-if="cart.length > 0">
                  <div class="row" v-if="cart.length > 0">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.total')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getTotalAmount()))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(a, i) in applied_gift_cards" :key="pc_key + i + i" v-if="cart.length > 0">
                    <div class="col-8">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$tc('book.giftCard', 1)}} ***{{a.code.slice(-4)}}
                        <i :style="{'color': bss.text_color_card}" style="cursor: pointer" @click="removeGiftCard(a)" class="tim-icons icon-simple-remove"></i>
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">-{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(a.remaining))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && (pay_deposit || applied_gift_cards.length > 0)">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{$t('book.dueNow')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_now))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && pay_deposit">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.dueLater')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_later))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
<!--              <span :style="{'color': bss.text_color_card, 'font-weight': 'bold', 'font-size': 'large'}">4</span>-->
                            <i class="tim-icons icon-cart"></i>
              <p class="d-sm-block d-none" v-if="bss.display_section_titles" :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{$t('book.headers.payment')}}</p>
            </template>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-10">
                <card :style="{ 'background-color': bss.card_color }">
                  <div class="row">
                    <div class="col">
                      <h3 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{$t('book.billingInfo')}}</h3>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="getTotalAmount() <= 0">
                    <div class="col ml-3 mr-3"
                         :style="{'border-radius': '.1875rem', 'min-height' : '100px', 'padding': '20px 0 15px', 'text-align': 'center', 'border': '1px solid ' + bss.button_color }">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: 2em">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.nothingDue')}}</h4>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="getTotalAmount() > 0 && allow_pay_on_arrival">
                    <div class="col">
                      <base-checkbox :border-color="bss.button_color" :check-mark-color="bss.card_color" v-on:input="selectPayOnArrival()" :accent-color="bss.button_color"
                                   v-model="pay_on_arrival"
                                   simple size="sm">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.payOnArrival')}}</span>
                      </base-checkbox>
                    </div>
                  </div>
                  <div class="row mb-3" v-if="getTotalAmount() > 0 && allow_deposit && !require_deposit">
                    <div class="col">
                      <base-checkbox :border-color="bss.button_color" :check-mark-color="bss.card_color"
                                     v-on:input="recalculatePromos('deposit')" :accent-color="bss.button_color"
                        v-model="pay_deposit"
                        simple size="sm">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.payDeposit')}} - {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(deposit))}} {{getCurrencyCode(locale.currency)}}</span>
                      </base-checkbox>
                    </div>
                  </div>

                  <label v-if="!pay_on_arrival && due_now > 0" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                    {{$t('book.paymentMethod')}}
                  </label>
                  <div class="row" v-if="!pay_on_arrival && due_now > 0">
                    <div class="col-lg-6">
                      <base-input :secondary-color="bss.text_color_card" placeholder="Select">
                        <base-dropdown  :style="{'font-family': bss.font_family_card }"
                                        title-classes="form-group form-control" :text-color="bss.button_color"
                                        :font-family="bss.font_family_card"
                                        :title="selectedPaymentOption.display_name"
                        >
                          <a v-for="(pm, i) in payment_methods" :key="'PM' + i" :style="{'font-family': bss.font_family_card }" style="white-space: normal; overflow-wrap: break-word;"
                             class="dropdown-item" href="#"  v-on:click="setPaymentOption(pm)">
                            {{ pm.display_name }}</a>
                        </base-dropdown>
                      </base-input>
                    </div>
                  </div>
                  <div class="row" v-show="!pay_on_arrival && due_now > 0">
                    <div class="col-12 col-lg-6">
                      <label v-if="selectedPaymentOption.name === 'Card'" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.nameOnCard')}}</label>
                      <label v-else :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.name')}}</label>
                      <base-input :secondary-color="bss.text_color_card" :error="errors.cardholder_first_name" :input-style="{'color': bss.text_color_card}"
                                  :placeholder="$t('book.placeholders.firstName')" v-model="payment.cardholder_first_name"></base-input>
                    </div>
                    <div class="col-12 col-lg-6">
                      <label class="d-sm-block d-none" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">&nbsp;</label>
                      <base-input :secondary-color="bss.text_color_card" :error="errors.cardholder_last_name" :input-style="{'color': bss.text_color_card}" :placeholder="$t('book.placeholders.lastName')" v-model="payment.cardholder_last_name" type="text"></base-input>
                    </div>
                  </div>

                  <div v-if="getTotalAmount() > 0 && selectedPaymentOption.name !== 'Card' && selectedPaymentOption.name !== 'Gift card'">
                    <div class="row">
                      <div class="col">
                        <span v-html="selectedPaymentOption.content"></span>
                      </div>
                    </div>
                  </div>

                  <div v-show="selectedPaymentOption.name === 'Card'">
                    <!--        Stripe-->
                    <div class="row mb-5" v-show="getTotalAmount() > 0 && payment_settings.payment_gateway_id === 3 && !pay_on_arrival && due_now > 0">
                      <div class="col">
                        <form id="payment-form">
                          <div id="payment-element">
                            <!--Stripe.js injects the Payment Element-->
                          </div>
                          <div id="payment-message" class="hidden"></div>
                        </form>
                      </div>
                    </div>

                    <!--                  Square-->
                    <div class="row" v-show="getTotalAmount() > 0 && payment_settings.payment_gateway_id === 2 && !pay_on_arrival && due_now > 0">
                      <div class="col">
                        <form id="payment-form-square">
                          <div id="card-container"></div>
                        </form>
                        <div id="payment-status-container"></div>
                      </div>
                    </div>

                    <!--                  Authorize.net-->
                    <div class="row" v-show="getTotalAmount() > 0 && payment_settings.payment_gateway_id === 1 && !pay_on_arrival && due_now > 0">
                      <div class="col-12 col-lg-5">
                        <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.ccn')}}</label>
                        <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-mask="'#### #### #### ####'" autocomplete="cc-number" placeholder="4444 4444 4444 4444" v-model="payment.cardNumber"></base-input>
                        <p :style="{'font-family': bss.font_family_card}" class="text-danger small" v-if="errors.ccn==true">{{$t('book.errors.ccn')}}</p>
                      </div>
                      <div class="col-12 col-lg-3">
                        <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.expiration')}}</label>
                        <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-mask="'##/##'" placeholder="11/27" v-model="payment.expirationDate"></base-input>
                        <p :style="{'font-family': bss.font_family_card}" class="text-danger small" v-if="errors.expiration==true">{{$t('book.errors.expiration')}}</p>
                      </div>
                      <div class="col-12 col-lg-2">
                        <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.cvv')}}</label>
                        <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-mask="'######'" placeholder="111" v-model="payment.cardCode" type="tel"></base-input>
                        <p :style="{'font-family': bss.font_family_card}" class="text-danger small" v-if="errors.cvv==true">{{$t('book.errors.cvv')}}</p>
                      </div>
                      <div class="col-12 col-lg-2">
                        <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.zip')}}</label>
                        <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}" v-mask="'NNNNNNNN'" placeholder="11111" v-model="payment.zip" type="text"></base-input>
                        <p :style="{'font-family': bss.font_family_card}" class="text-danger small" v-if="errors.zip==true">{{$t('book.errors.zip')}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="selectedPaymentOption.name === 'Gift card' && !pay_on_arrival && due_now > 0">
                    <div class="col">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.giftCardCode')}}</label>
                      <div style="display: flex; width: 100%;">

                        <base-input :secondary-color="bss.text_color_card" v-model="gift_card_code"
                                    style="flex-grow: 1; border-radius: 0px;" :error="errors.gift_card_code"
                                    :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                                    :placeholder="$t('book.giftCardCode')">
                        </base-input>
                        <base-button simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                                     :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                                     v-on:click="applyGiftCard()">
                          {{$t('book.apply')}}
                        </base-button>
                      </div>
                    </div>

<!--                    <div class="col">-->
<!--                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">Gift card code</label>-->
<!--                      <base-input :secondary-color="bss.text_color_card" :input-style="{'color': bss.text_color_card}"-->
<!--                                  placeholder="Gift card code" v-model="gift_card_code" :error="errors.gift_card_code">-->
<!--                      </base-input>-->
<!--                    </div>-->
                  </div>

                  <div class="row">
                    <div class="col-lg-1 col-2">
                      <base-button
                        :style="{ 'font-family': bss.font_family_card,
                        'border-color': bss.button_color,
                        'color': bss.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                        v-on:click="tabTwo(2)" link >
                        <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                      </base-button>
                    </div>
                    <div class="col-10 col-lg-11">
                      <base-button v-if="payment_settings.payment_gateway_id === 4 && selectedPaymentOption.name === 'Card'" :disabled="disable_submit"
                        :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"
                        v-on:click="tabFive(5)" type="primary" simple >
                        {{$t('book.buttons.makePayment')}}</base-button>
                      <base-button v-if="!(payment_settings.payment_gateway_id === 4 && selectedPaymentOption.name === 'Card')" :disabled="disable_submit"
                                   :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"
                                   v-on:click="tabFive(5)" type="primary" simple >
                        {{$t('book.buttons.completeBooking')}}</base-button>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-lg-3 col-10">
                <card :style="{ 'background-color': bss.card_color}">
                  <div class="row justify-content-center">
                    <base-button :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color }"  v-on:click="tabZero(0)" type="success" class="button-wide no-upper-margin" simple size="sm">
                      {{$t('book.buttons.addAnotherBooking')}}</base-button>
                  </div>
                  <div class="row mt-3" v-if="cart.length > 0">
                    <div class="col-11">
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{$t('book.yourPurchases')}}
                        <i class="tim-icons icon-cart"></i>
                      </h4>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="cart.length <= 0">
                    <div class="col ml-2 mr-2"
                         :style="{'border-radius': '.1875rem', 'min-height' : '100px', 'padding': '20px 0 15px', 'text-align': 'center', 'border': '1px solid ' + bss.button_color }">
                        <span :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: 2em">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.yourCartIsEmpty')}}</h4>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0">
                    <div class="col-12">
                      <div  v-for="(c, index) in cart" :key="'A' + index">
                        <div v-if="c.hasOwnProperty('gift_card')">
                          <div class="row">
                            <div class="col-3">
                              <img class="game-img" style="object-fit: contain" :src="c.gift_card.design_url.url"/>
                            </div>
                            <div class="col-9">
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{$tc('book.giftCard', 1)}}
                                <base-button
                                  @click="removeBooking(index, c)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                              </p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.quantity')}}:</span> {{c.gift_card.quantity}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.giftCardValue')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(c.subtotal))}} {{getCurrencyCode(locale.currency)}}</p>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="col-3"
                                 v-if="c.game.hasOwnProperty('game_images') && c.game.game_images !== null && c.game.game_images.length > 0 ">
                              <img class="game-img" :src="JSON.parse(c.game.game_images)[0].url"/>
                            </div>
                            <div class="col-9">
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{c.game.game_name}}
                                <base-button
                                  @click="removeBooking(index, c)"
                                  class="like btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                ><i class="tim-icons icon-trash-simple"></i>
                                </base-button>
                              </p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.date')}}:</span> {{getDayOfWeek(c.booking.booking_date)}} {{getFormattedDate(locale.date_format, c.booking.booking_date)}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.time')}}:</span> {{getTime(locale.time_format, c.booking.start_time)}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.quantity')}}:</span> {{c.quantity}}</p>
                              <p v-if="ppp" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.rate')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getAmount(ppp)}} {{getCurrencyCode(locale.currency)}} {{$t('book.perPerson')}}</p>
                              <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}"><span class="font-weight-bold">{{$t('book.bookingSubtotal')}}:</span> {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(c.subtotal)}} {{getCurrencyCode(locale.currency)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="bss.show_promo_code_field === 1">
                    <div class="col">
                      <div style="display: flex; width: 100%;">
                        <base-input :secondary-color="bss.text_color_card" v-model="promo_code"
                                    style="flex-grow: 1; border-radius: 0px;"
                                    :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                                    :placeholder="bss.promo_code_placeholder_text">
                        </base-input>
                        <base-button simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                                     :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                                     v-on:click="applyPromo()">
                          {{$t('book.apply')}}
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="payment_settings.allow_tipping
                  && ((payment_settings.allow_preset_tipping_options && tipping_options.length > 0)
                  || payment_settings.allow_custom_tipping_options)">
                    <div class="col">
                      <label :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{payment_settings.tip_label}}</label>
                      <base-input :secondary-color="bss.text_color_card" v-if="payment_settings.allow_preset_tipping_options && tipping_options.length > 0">
                        <base-dropdown :style="{'font-family': bss.font_family_card }"
                                       title-classes="form-group form-control" :text-color="bss.button_color"
                                       :font-family="bss.font_family_card"
                                       :title="tip_title"
                        >
                          <a v-for="(t, i) in tipping_options"  :style="{'font-family': bss.font_family_card }"
                             style="white-space: normal; overflow-wrap: break-word;"
                             class="dropdown-item" href="#" :key="'T' + i" v-on:click="computeTip(t)">
                            {{ getTipAmount(t) }}</a>
                        </base-dropdown>
                      </base-input>

                      <div style="display: flex; width: 100%;">
                        <base-input
                          v-if="(payment_settings.allow_custom_tipping_options && !payment_settings.allow_preset_tipping_options) || selectedCustomTipOption"
                          :secondary-color="bss.text_color_card" v-model.number="applied_tip_amount"
                          style="flex-grow: 1; border-radius: 0px;"
                          :input-style="{'border-color': bss.button_color, 'color': bss.text_color_card,
                              'border-radius': '5px 0px 0px 5px'}"
                          :placeholder="tip_placeholder">
                        </base-input>
                        <base-button v-if="(payment_settings.allow_custom_tipping_options && !payment_settings.allow_preset_tipping_options) || selectedCustomTipOption"
                                     simple style="flex-grow: 0; border-radius: 0px 5px 5px 0px; border-left: none;"
                                     :style="{ 'font-family': bss.font_family_card,
                         'background-color': bss.card_color,
                         'margin-left': '0px', 'margin-top': '0px', 'margin-bottom': '10px',
                         'padding-top': '0px', 'padding-bottom': '0px',
                         'padding-left': '10px', 'padding-right': '10px',
                         'border-color': bss.button_color, 'color': bss.button_color }"
                                     v-on:click="computeTip({id: 0, name: 'Custom amount', amount: applied_tip_amount, value_type: payment_settings.custom_tipping_value_type})">
                          {{$t('book.apply')}}
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <hr :style="{ 'background-color': bss.text_color_card}" v-if="cart.length > 0">
                  <div class="row" v-if="cart.length > 0">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.subtotal')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getCartSubtotal()}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(t, i) in taxes" :key="'J' + i" v-if="cart.length > 0 && hasBookingsInCart()">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ t.name }}</p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getTax(t.amount, t.value_type, t.is_compound, t.apply_after_discount)))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(f, i) in fees" :key="'K' + i" v-if="cart.length > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ f.name }}</p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getFee(f.amount, f.value_type)))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && applied_tip > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.tip')}}
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(applied_tip))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(p, i) in promo_codes" :key="pc_key + i" v-if="cart.length > 0">
                    <div class="col-6">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{ p.name }}
<!--                        <base-button :style="{'color': bss.text_color_card}" @click="removePromoCode(p)" style="padding: 0; margin: 0"  icon class="btn-link d-inline">-->
                          <i style="cursor: pointer" :style="{'color': bss.text_color_card}" @click="removePromoCode(p)"  class="tim-icons icon-simple-remove"></i>
<!--                        </base-button>-->
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">-{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(p.value_amount))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <hr :style="{ 'background-color': bss.text_color_card}" v-if="cart.length > 0">
                  <div class="row" v-if="cart.length > 0">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.total')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(getTotalAmount()))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-for="(a, i) in applied_gift_cards" :key="pc_key + i + i" v-if="cart.length > 0">
                    <div class="col-8">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$tc('book.giftCard', 1)}} ***{{a.code.slice(-4)}}
                        <i :style="{'color': bss.text_color_card}" style="cursor: pointer" @click="removeGiftCard(a)" class="tim-icons icon-simple-remove"></i>
                      </p>
                    </div>
                    <div class="col">
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">-{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(a.remaining))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && (pay_deposit || applied_gift_cards.length > 0)">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{$t('book.dueNow')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card, 'font-weight': 'bold'}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_now))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && pay_deposit">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.dueLater')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_later))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                  <div class="row" v-if="cart.length > 0 && pay_on_arrival">
                    <div class="col">
                      <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.dueNow')}}</p>
                    </div>
                    <div class="col" >
                      <p style="float:right" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(0))}} {{getCurrencyCode(locale.currency)}}</p>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </wizard-tab>
          <wizard-tab :before-change="() => wizardComplete()">
            <template slot="label">
<!--              <span :style="{'color': bss.text_color_card, 'font-weight': 'bold', 'font-size': 'large'}">5</span>-->

                            <i class="tim-icons icon-check-2"></i>
              <p class="d-sm-block d-none" v-if="bss.display_section_titles" :style="{'font-family': bss.font_family, 'color': bss.text_color}">{{$t('book.review')}}</p>
            </template>
            <div class="row justify-content-center">
              <div class="col-lg-6 col-10">
                <card :style="{ 'background-color': bss.card_color}">
                  <div class="row ml-1 mr-1 justify-content-center">
                    <h3 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">{{$t('book.thanks')}} {{bss.company_name}}!</h3>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col">
                      <hr class="white-content">
                      <h4 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.bookingDetails')}}</h4>

                      <div class="row mt-3 mr-2 justify-content-end">
                        <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">{{$t('book.orderReference')}}: #{{order_number}}</p>
                      </div>
                      <div class="row mr-2 justify-content-end">
                        <p v-if="!pay_on_arrival && !pay_deposit"
                           :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                          {{$t('book.paymentStatus')}}: <span class="text-success text-uppercase">{{$t('book.paid')}}</span>
                        </p>
                        <p v-if="!pay_on_arrival && pay_deposit"
                           :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                          {{$t('book.paymentStatus')}}: <span class="text-warning text-uppercase">{{$t('book.partiallyPaid')}}</span>
                        </p>
                        <p v-if="pay_on_arrival "
                           :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">
                          {{$t('book.paymentStatus')}}: <span v-if="applied_gift_cards.length <= 0" class="text-danger">{{$t('book.unpaid')}}</span>
                          <span v-if="applied_gift_cards.length > 0" class="text-warning">{{$t('book.partiallyPaid')}}</span>
                        </p>
                      </div>

                      <div class="row mt-3" v-for="(c, index) in cart" :key="'RP' + index">
                        <div v-if="c.hasOwnProperty('gift_card')">
                          <div class="col-12">
                            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                              <span >{{$tc('book.giftCard', 1)}}</span> {{$t('book.forTheAmountOf')}} {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(c.gift_card.total))}} {{getCurrencyCode(locale.currency)}}
                            </p>
                          </div>
                          <div class="col-12">
                            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{$t('book.quantity')}}: {{c.gift_card.quantity}}
                            </p>
                          </div>
                        </div>
                        <div v-else>
                          <div class="col-12">
                            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                              <span >{{c.game.game_name}}</span>: {{getTime(locale.time_format, c.booking.start_time)}}, {{getDayOfWeek(c.booking.booking_date)}} {{getFormattedDate(locale.date_format, c.booking.booking_date)}}
                            </p>
                          </div>
                          <div class="col-12">
                            <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">{{$t('book.quantity')}}: {{c.quantity}}
                            </p>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-12">
                          <p v-if="due_now > 0" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                            {{$t('book.amountPaid')}}: {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_now))}} {{getCurrencyCode(locale.currency)}}
                          </p>
                          <p v-if="due_later > 0" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                            {{$t('book.amountDue')}}: {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due_later))}} {{getCurrencyCode(locale.currency)}}
                          </p>
                        </div>
                      </div>
                      <div class="row justify-content-center mt-3" v-if="!bss.auto_redirect">
                        <div class="col-12 col-lg-6">
                          <base-button :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"  v-on:click="goHome()" simple>
                            {{ bss.redirect_button_text }}</base-button>
                          <base-button v-if="hasBookingsInCart()" :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"  v-on:click="goToCheckIn()" class="button-wide" simple>{{$t('book.checkIn')}}</base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>


          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { SimpleWizard, WizardTab } from 'src/components'
import SelectGame from "@/pages/Bookings/SelectGame";
import swal from 'sweetalert2'
import {Auth} from "aws-amplify";
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import {Input, Button, Carousel, CarouselItem} from "element-ui";
import {formattedDate, date, time, dayOfWeek} from "@/plugins/dateFormatter";
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
import _ from "lodash";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import gbLocale from '@fullcalendar/core/locales/en-gb';
import currencies from "@/assets/currencies.json";
import { BFormRating } from 'bootstrap-vue'
import es from '@fullcalendar/core/locales/es';
import fr from '@fullcalendar/core/locales/fr';


export default {
  name: "CreateBooking",
  components: {
    SimpleWizard,
    WizardTab,
    SelectGame,
    [Input.name]: Input,
    [Button.name]: Button,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    NotFoundPage,
    FullCalendar,
    BFormRating
  },
  data() {
    return {
      selectedCf: '',
      applied_tip: 0,
      applied_tip_amount: '',
      tip_title: this.$t('book.tipAmount'),
      selectedCustomTipOption: false,
      tipping_options: [],
      tip_placeholder: this.$t('book.tipAmountPercent'),
      applied_gift_cards: [],
      payment_methods: [],
      gift_card_link: false,
      cart_id: '',
      is_gift_card: false,
      gift_card_code: '',
      selectedPaymentOption: {id: 0, name: ''},
      disable_submit: true,
      selectedPolicy: '',
      modals: { policy: false },
      custom_fields: [],
      payment_intent_id: '',
      gc_key: 0,
      gift_card_settings: {},
      gift_card_designs: [],
      gift_card_amounts: [],
      buttonGroupStyle: [],
      buttonGroupPriceStyle: [],
      gift_card: {
        personal_message: '',
        design_url: '',
        custom_amount: '',
        recipient_first_name: '',
        recipient_last_name: '',
        recipient_email: '',
        total: 0,
        quantity: 1,
        send_to_self: 0,
        send_instantly: true,
        delivery_date: new Date(),
        terms: false
      },
      selected_gift_card_amount: '',
      gift_card_select: false,
      rows: 0,
      max_time_slots: 0,
      currencies: currencies.currencies,
      value: new Date(),
      bss: '',
      locale: '',
      hash: '',
      notFound: false,
      day_of_week: '',
      promo_code: '',
      promo_codes: [],
      monday: '',
      sunday: '',
      subtotal: 0,
      number_of_players: 0,
      loading: false,
      empty: false,
      games: '',
      pricing: '',
      ppp: '',
      booking_slots: '',
      incrementCount: 0,
      selectedGame: {
        difficulty_level: 0
      },
      selectedBooking: '',
      cart: [],
      week: [],
      is_authenticated: false,
      day: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
      month: ["January","February","March","April","May","June","July",
        "August","September","October","November","December"],
      phone_placeholder: '(999) 999-9999',
      user: {
        first_name: '',
        last_name: '',
        email: '',
        phone: ''
      },
      payment: {
        amount: '',
        cardNumber: '',
        expirationDate: '',
        cardCode: '',
        zip: '',
        cardholder_first_name: '',
        cardholder_last_name: ''
      },
      order_number: '',
      timestamp: '',
      errors: {
        gift_card_code: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        ccn: false,
        zip: false,
        expiration: false,
        cvv: false,
        cardholder_first_name: "",
        cardholder_last_name: "",
        number_of_players: '',
        gift_card: {
          email: '',
          first_name: '',
          last_name: '',
          custom_amount: '',
          terms: ''
        }
      },
      j: 0,
      pay_on_arrival: false,
      allow_pay_on_arrival: false,
      allow_deposit: false,
      require_deposit: false,
      pay_deposit: false,
      deposit: 0,
      due_now: 0,
      due_later: 0,
      taxes: [],
      fees: [],
      pricing_categories: [],
      price_settings: '',
      base_pricing: false,
      payment_settings: '',
      card: '',
      elements: '',
      prevent_submit: false,
      stripe: '',
      square: '',
      squareAppId: '',
      squareLocationId: '',
      currentDay: '',
      currentTime: '',
      pc_key: 0,
      show_calendar: false,
      time_slot_height: '50px',
      time_slot_key: 0,
      min_period: {},
      calendarOptions: {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        initialView: 'timeGridWeek',
        allDaySlot: false,
        locale: this.$i18n.locale,
        firstDay: 1,
        headerToolbar: {
          left: '',
          right: '' // Replace 'title' with your custom title
        },
        views: {
          timeGridDay: { // name of view
            titleFormat: { year: '2-digit', month: '2-digit', day: '2-digit' }
            // other view-specific options here
          }
        },
        scrollTime: '10:00:00',
        stickyHeaderDates: true,
        height: 700,
        initialEvents: [],
        slotDuration: '00:30:00',
        slotLabelInterval: 30,
        slotLabelFormat: function (date) {
          let hour = date.date.hour
          let dd = 'AM'
          if (date.date.minute == 0){
            if (parseInt(hour) >= 12) {
              hour = parseInt(date.date.hour) - 12;
              dd = 'PM'

            }
            if (hour == 0) {
              hour = 12;
            }
            return hour + ':00 ' + dd
          }
        },
        datesSet: this.getBookingSlots,
        eventClick: this.handleEventClick,
        editable: true,
        selectable: false,
      },
      calendarHooks: {
        dayCellContent({ date, dayEl }) {
          if (date.toDateString() === new Date().toDateString()) {
            dayEl.style.backgroundColor = 'transparent';
          }
        },
      },
    }
  },
  computed: {
    isMobile() {
      const userAgent = navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return mobileRegex.test(userAgent);
    }
  },
  methods: {
    payOnArrivalRequired(){
      for(let n in this.payment_methods){
        if(this.payment_methods[n].id === -1){
          this.pay_on_arrival = true
          this.pay_deposit = false
          return true
        }
      }
      return false
    },
    computeTip(tip){
      if(tip.id === 0 && !this.selectedCustomTipOption && this.payment_settings.allow_preset_tipping_options){
        this.tip_title = tip.name
        this.selectedCustomTipOption = true
        return
      } else {
        this.selectedCustomTipOption = false
      }
      if(isNaN(tip.amount)){
        tip.amount = ''
        return
      }
      if(tip.value_type === 0){
        this.tip_title = tip.name + ' ' + tip.amount + '%'
        this.applied_tip = this.getPercentage(tip.amount, this.subtotal)
      } else {
        this.tip_title = tip.name + ' ' + this.getCurrencySymbol(this.locale.currency) + this.roundToFixed(tip.amount, 2) + this.getCurrencyCode(this.locale.currency)
        this.applied_tip = tip.amount
      }
    },
    getTipAmount(tip){
      if(tip.id === 0){
        return tip.name
      } else if(tip.value_type === 0){
        return tip.name + ' ' + tip.amount + '%'
      }
      return tip.name + ' ' + this.getCurrencySymbol(this.locale.currency) + this.roundToFixed(tip.amount, 2) + this.getCurrencyCode(this.locale.currency)
    },
    isFieldVisibile(field){
      if((field.visibility === 1 && field.visibility_conditions) ||
        (field.visibility === 1 && field.show_when_gift_card_in_cart)){
        let allowed_games = JSON.parse(field.visibility_conditions)

        let found = 0
        let found_row = ''
        for(let n in this.cart){
          found_row = _.find(allowed_games, {id: this.cart[n].game.game_id})
          if(found_row) {
            found++
          }
          if(this.cart[n].hasOwnProperty('gift_card') && field.show_when_gift_card_in_cart){
            found++
          }
        }
        if(found <= 0){
          return false
        }
      }
      return true
    },
    setPaymentOption(option){
      this.disable_submit = true
      if(option.name !== 'Card'){
        if(this.payment_settings.payment_gateway_id === 1){
          this.payment.cardNumber = ''
          this.payment.expirationDate = ''
          this.payment.amount = ''
          this.payment.zip = ''
          this.payment.cardCode = ''
        }
        this.payment.cardholder_first_name = this.user.first_name
        this.payment.cardholder_last_name = this.user.last_name
      } else {
        if(this.payment_settings.payment_gateway_id === 1 || this.payment_settings.payment_gateway_id === 2
          || this.payment_settings.payment_gateway_id === 4){
          this.disable_submit = false
        }

        if(this.payment_settings.payment_gateway_id === 2){
          this.initializeSquare()
        }
      }
      // if(option.name !== 'Card' && option.name !== 'Gift card'){
      //   this.allow_deposit = false
      //   this.pay_deposit = false
      //   this.pay_on_arrival = false
      //   this.allow_pay_on_arrival = false
      //   this.disable_submit = false
      // } else {
      //   this.allow_pay_on_arrival = this.bss.allow_pay_on_arrival
      //   this.computeDeposit()
      // }
      this.allow_pay_on_arrival = this.bss.allow_pay_on_arrival
      this.computeDeposit()

      this.selectedPaymentOption = option
      this.setDisableSubmit()
    },
    setDisableSubmit(){
      if(this.due_now > 0 && this.selectedPaymentOption.name === 'Gift card'){
        this.disable_submit = true
      } else {
        this.disable_submit = false
      }
      if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 3){
        this.disable_submit = true
      }
    },
    getDropDownLabel(item){
      if(item){
        return item.name
      }
      return ''
    },
    selectDropdown(question, option){
      question.customer_response = option
      this.j++
    },
    getBookingsFromCart(){
      let bookings =  []
      let gift_cards = []
      for(let n in this.cart){
        if(this.cart[n].hasOwnProperty('gift_card')){
          if(this.cart[n].gift_card.send_to_self){
            this.cart[n].gift_card.recipient_first_name = this.user.first_name
            this.cart[n].gift_card.recipient_last_name = this.user.last_name
            this.cart[n].gift_card.recipient_email = this.user.email
          }
          gift_cards.push(this.cart[n])
        } else {
          bookings.push(this.cart[n])
        }
      }
      return { bookings, gift_cards}
    },
    async payWithYappy(){
      let errors = 0
      if(this.payment.cardholder_last_name == '' && !this.pay_on_arrival){
        this.errors.cardholder_last_name = this.$t('book.errors.lastName')
        errors++
      } else {
        this.errors.cardholder_last_name = ""
      }
      if(this.payment.cardholder_first_name == '' && !this.pay_on_arrival){
        this.errors.cardholder_first_name = this.$t('book.errors.firstName')
        errors++
      } else {
        this.errors.cardholder_first_name = ""
      }
      if(errors > 0){
        return
      }
      let custom_fields = []
      let resp = ''
      for(let n in this.custom_fields){
        resp = this.custom_fields[n].customer_response
        if(this.custom_fields[n].type === 3 && this.custom_fields[n].customer_response){
          if(this.custom_fields[n].customer_response.has_follow_up_field){
            resp = this.custom_fields[n].customer_response.name + ': ' + this.custom_fields[n].customer_response.follow_up_response
          } else {
            resp = this.custom_fields[n].customer_response.name
          }
        }
        custom_fields.push({
          customer_response: resp,
          id: this.custom_fields[n].id,
          type: this.custom_fields[n].type
        })
      }

      let code = this.$route.params.company
      let url = API_LOCATION + 'yappy?code=' + code
      let cart = this.getBookingsFromCart()
      let data = {
        tip: this.roundToFixed(this.applied_tip, 2),
        cart: this.cart_id,
        bookings: cart.bookings,
        gift_cards: cart.gift_cards,
        user: this.user,
        payment: this.payment,
        discount: this.getDiscounts(),
        promo_codes: this.promo_codes,
        total: this.roundToFixed(this.due_now, 2),
        currency: this.locale.currency,
        deposit: this.pay_deposit ? this.due_now : null,
        custom_fields: custom_fields,
        applied_gift_cards: this.applied_gift_cards,
        locale: this.$i18n.locale
      }

      this.loading = true
      axios.post(url, data)
        .then(response => {
          this.loading = false
          // window.location.href = response.data.url
          window.open(response.data.url, '_blank');
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          } else {
            swal({
              title: this.$t('book.swals.invalidTransaction'),
              text: err.response.data,
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: this.$t('book.swals.backToBooking')
            }).then((response)=> {
              this.tabZero(0)
            })
          }
        })
    },
    addMetaPixel(pixel_id) {
      // Check if Pixel is already added to avoid duplicates
      if (!window.fbq) {
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        // Replace 'YOUR_PIXEL_ID' with your actual Meta Pixel ID
        fbq('init', pixel_id);
        fbq('track', 'PageView');
      }
    },
    initializeGtm(gtmId) {
      if (!gtmId) return; // Check if GTM ID is provided

      // GTM script content
      const gtmScriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`;

      // Create a new script element
      const scriptElement = document.createElement('script');
      scriptElement.async = true;
      scriptElement.textContent = gtmScriptContent;

      // Insert the script element into the document head
      document.head.appendChild(scriptElement);

      // Insert the noscript fallback
      const iframe = document.createElement('noscript');
      const iframeContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      iframe.innerHTML = iframeContent;
      document.body.insertBefore(iframe, document.body.firstChild);
    },
    insertGoogleAnalyticsScript(gaMeasurementId) {
      if (!gaMeasurementId) return; // Check if GA Measurement ID is provided

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      if (!window.gtag) {
        const script = document.createElement('script');
        script.async = true;
        script.onload = () => {
          window.gtag = gtag; // Define the global gtag function after the script loads
          gtag('js', new Date());
          gtag('config', gaMeasurementId);
        };
        script.src = `https://www.googletagmanager.com/gtag/js?id=` + gaMeasurementId;
        document.head.appendChild(script);
      }

      if (window !== window.top) {
        let eventData = {
          eventType: 'page_view'
        }
        this.$nextTick(() => {
          this.postMessageTrackingEvent(eventData)
        });
      } else {
        gtag('event', 'page_view');
      }
    },
    agreeToPolicy(){
      this.selectedCf.customer_response = true
      this.modals.policy = false
    },
    openPolicy(item, field){
      this.selectedCf = field
      this.selectedPolicy = item
      this.modals.policy = true
    },
    getArrowSetting(images){
      if(!images){
        return ''
      }
      if(images.length <= 1){
        return 'never'
      }
      return "hover"
    },
    selectPayOnArrival(){
      this.applied_gift_cards = []
      if(this.pay_on_arrival){
        this.disable_submit = false
      } else {
        if(this.payment_settings.payment_gateway_id === 3 ||
          this.selectedPaymentOption.name === 'Gift card'){
          this.disable_submit = true
        }
      }
      this.pay_deposit = false
    },
    selectPayDeposit(){
      this.due_now = this.deposit
      this.pay_on_arrival = false
      let gift_card_amount = 0
      for(let n in this.cart){
        if(this.cart[n].hasOwnProperty('gift_card')){
          gift_card_amount += this.cart[n].gift_card.total
        }
      }
      this.due_now = this.due_now + gift_card_amount
      let gift_card_payments = 0
      for(let n in this.applied_gift_cards){
        this.due_now = parseFloat(this.due_now) - this.applied_gift_cards[n].remaining
        gift_card_payments += this.applied_gift_cards[n].remaining
      }

      if(this.due_now < 0){
        this.due_now = 0
        this.disable_submit = false
      }
      this.due_later = this.getTotalAmount() - this.due_now - gift_card_payments
      if(!this.pay_deposit){
        this.due_now = this.getDueNow()
        this.due_later = 0
      }
    },
    getDueNow(){
      let due_now = this.getTotalAmount()
      for(let n in this.applied_gift_cards){
        due_now = due_now - this.applied_gift_cards[n].remaining
      }
      return due_now
    },
    incrementCounterGCQuantity(){
      if(this.gift_card.quantity >= this.gift_card_settings.gift_cards_max_purchase_amount){
        return
      }
      else {
        this.gift_card.quantity++
      }
      this.getGiftCardTotal('quantity')
    },
    decrementCounterGCQuantity(){
      if(this.gift_card.quantity <= 1){
        return
      }
      else {
        this.gift_card.quantity--
      }
      this.getGiftCardTotal('quantity')
    },
    incrementCounter(c, source){
      let total = 0
      for(let n in this.pricing_categories){
        total += this.pricing_categories[n].number_of_players
      }

      if(c.number_of_players >= this.selectedGame.max_players_count || total >= this.selectedGame.max_players_count){
        this.errors.number_of_players = this.$t('book.errors.totalNumberOfPlayersGreaterThan') + this.selectedGame.max_players_count
        return
      } else if(c.number_of_players >= c.maximum_players_for_category){
        this.errors.number_of_players = this.$t('book.errors.totalNumberOfPlayersFor') + c.category + this.$t('book.errors.cannotBeGreaterThan') + c.maximum_players_for_category
        return
      }
      else {
        this.errors.number_of_players = ''
        c.number_of_players++
      }
      if(source === 'gc'){
        this.getGiftCardTotal('players',  this.selected_gift_card_amount, this.selectedGame)
      } else {
        this.getSubtotal()
      }
    },
    decrementCounter(c, source){
      let total = 0
      for(let n in this.pricing_categories){
        total += this.pricing_categories[n].number_of_players
      }
     if(c.number_of_players <= c.minimum_participants){
        this.errors.number_of_players = this.$t('book.errors.numberOf') + c.category + this.$t('book.errors.cannotBeLessThan') + c.minimum_participants
       return
        // c.number_of_players = c.minimum_participants
     } else if (total <= this.selectedGame.min_players_count){
       this.errors.number_of_players = this.$t('book.errors.totalNumberOfPlayersLessThan')  + this.selectedGame.min_players_count
       return
     } else {
       this.errors.number_of_players = ''
        c.number_of_players--
      }
      if(source === 'gc'){
        this.getGiftCardTotal('players', this.selected_gift_card_amount, this.selectedGame)
      } else {
        this.getSubtotal()
      }
    },
    selectGiftCardDeliveryMethod(source){
      this.gift_card.send_instantly = source
      if(source){
        this.buttonGroupStyle[1] = { 'font-family': this.bss.font_family_card,
          'border-color': this.bss.button_color,
          'color': this.bss.button_color}
        this.buttonGroupStyle[0] = { 'font-family': this.bss.font_family_card,
          'border-color': this.bss.button_color,
          'background-color': this.bss.button_color,
          'background-image': 'linear-gradient(to bottom left,' + this.bss.button_color + ', ' + this.bss.button_color + ', ' + this.bss.button_color + ') !important',
          'color': this.bss.card_color}
      } else {
        this.buttonGroupStyle[0] = { 'font-family': this.bss.font_family_card,
          'border-color': this.bss.button_color,
          'color': this.bss.button_color}
        this.buttonGroupStyle[1] = { 'font-family': this.bss.font_family_card,
          'border-color': this.bss.button_color,
          'background-color': this.bss.button_color,
          'background-image': 'linear-gradient(to bottom left,' + this.bss.button_color + ', ' + this.bss.button_color + ', ' + this.bss.button_color + ') !important',
          'color': this.bss.card_color}
      }
    },
    setGiftCardDesign(index){
      this.gift_card.design_url = this.gift_card_designs[index]
    },
    async getGiftCardTotal(source, item, game){

      if(item){
        this.selected_gift_card_amount = item
      }
      if(game){
        this.selectedGame = game
      }
      if(source === 'custom'){
        if(this.gift_card.custom_amount === ''){
          return
        }
        if(isNaN(this.gift_card.custom_amount)){
          this.gift_card.custom_amount = this.gift_card_settings.gift_cards_min_purchase_value
        }
        if(this.gift_card.custom_amount < this.gift_card_settings.gift_cards_min_purchase_value){
          this.gift_card.custom_amount = this.gift_card_settings.gift_cards_min_purchase_value
        } else if (this.gift_card.custom_amount > this.gift_card_settings.gift_cards_max_purchase_value){
          this.gift_card.custom_amount = this.gift_card_settings.gift_cards_max_purchase_value
        }
      }
      this.gift_card.total = 0
      this.number_of_players = 0
      if(this.selected_gift_card_amount && this.selected_gift_card_amount.amount === 'Custom value'){
        //Validation
        if(this.gift_card.custom_amount > this.gift_card_settings.gift_cards_max_purchase_value){
          this.gift_card.custom_amount = this.gift_card_settings.gift_cards_max_purchase_value
        } else if (this.gift_card.custom_amount < this.gift_card_settings.gift_cards_min_purchase_value){
          this.gift_card.custom_amount = this.gift_card_settings.gift_cards_min_purchase_value
        }
        this.gift_card.total = this.gift_card.custom_amount * this.gift_card.quantity

      } else if(this.selected_gift_card_amount && this.selected_gift_card_amount.amount === 'Value of a game'){
        if(source !== 'game' && source !== 'players' && source !== 'quantity'){
          this.selectedGame = {
            game_id: this.games[0].game_id,
            game_name: this.games[0].game_name,
            max_players_count: this.games[0].max_players_count,
            min_players_count: this.games[0].min_players_count,
            pricing_type_id: this.games[0].pricing_type_id
          }
        }
        if(source === 'game' || !source || source === 'dropdown'){
          let test  = await this.getGiftCardPricing()
        } else {
          this.getGiftCardGamePrice()
        }
      } else {
        this.gift_card.total = this.selected_gift_card_amount.amount * this.gift_card.quantity
      }
      this.gc_key++
    },
    getGiftCardGamePrice(){
      this.number_of_players = 0
      if(this.selectedGame.cumulative_category_pricing){
        if(this.selectedGame.pricing_type_id === 1){
          for(let m in this.pricing_categories) {
            this.number_of_players += this.pricing_categories[m].number_of_players
          }
          for(let m in this.pricing_categories){
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.number_of_players >= this.pricing[n].min_players
                && this.number_of_players <= this.pricing[n].max_players) {
                this.gift_card.total += this.pricing[n].price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.number_of_players > this.pricing[last_index].max_players){
              this.gift_card.total += this.pricing[last_index].price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.total += smallest_price_category.price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for(let m in this.pricing_categories) {
            this.number_of_players += this.pricing_categories[m].number_of_players
          }
          for(let m in this.pricing_categories){
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.number_of_players >= this.pricing[n].min_players
                && this.number_of_players <= this.pricing[n].max_players) {
                this.gift_card.total += this.pricing[n].price *  this.gift_card.quantity
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.number_of_players > this.pricing[last_index].max_players){
              this.gift_card.total += this.pricing[last_index].price *  this.gift_card.quantity
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.gift_card.total += smallest_price_category.price *  this.gift_card.quantity
            }
          }
        }
      } else {
        if(this.selectedGame.pricing_type_id === 1){
          for(let m in this.pricing_categories){

            this.number_of_players += this.pricing_categories[m].number_of_players
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.pricing_categories[m].number_of_players >= this.pricing[n].min_players
                && this.pricing_categories[m].number_of_players <= this.pricing[n].max_players) {
                this.gift_card.total += this.pricing[n].price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players > this.pricing[last_index].max_players){
              this.gift_card.total += this.pricing[last_index].price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players !== 0 &&
              this.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.total += smallest_price_category.price * this.pricing_categories[m].number_of_players *  this.gift_card.quantity
            }
          }
        }
        else if(this.selectedGame.pricing_type_id === 2){
          for(let m in this.pricing_categories){
            this.number_of_players += this.pricing_categories[m].number_of_players
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.pricing_categories[m].number_of_players >= this.pricing[n].min_players
                && this.pricing_categories[m].number_of_players <= this.pricing[n].max_players) {
                this.gift_card.total += this.pricing[n].price *  this.gift_card.quantity
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players > this.pricing[last_index].max_players){
              this.gift_card.total += this.pricing[last_index].price *  this.gift_card.quantity
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players !== 0 &&
              this.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.gift_card.total += smallest_price_category.price *  this.gift_card.quantity
            }
          }
        }
      }

      let taxes = 0
      let fees = 0
      if(this.gift_card_settings.gift_cards_include_taxes_and_fees){
        for(let t in this.taxes){
          if(this.taxes[t].pricing_type === 0){
            //value (total)
            if(this.taxes[t].value_type === 0){
              taxes = taxes + this.taxes[t].amount
            }
            //value (per person)
            if(this.taxes[t].value_type === 1){
              taxes = taxes + (this.taxes[t].amount * this.number_of_players)
            }
            //percentage
            if(this.taxes[t].value_type === 2){
              taxes = taxes + (this.gift_card.total * (this.taxes[t].amount * 0.01))
            }
          }
        }

        for(let t in this.fees){
          if(this.fees[t].value_type === 0){
            fees = fees + this.fees[t].amount
          }
          if(this.fees[t].value_type === 1){
            fees = fees  + (this.fees[t].amount  * this.number_of_players)
          }
          if(this.fees[t].value_type === 2){
            fees = fees + (this.gift_card.total * (this.fees[t].amount * 0.01))
          }
        }
      }

      this.gift_card.total = this.gift_card.total + taxes + fees
    },
    getGiftCardPricing(){
      this.loading = true
      let url = API_LOCATION + 'games/' + this.selectedGame.game_id + '/pricing'
      axios.get(url)
        .then(response => {
          this.loading = false
          this.pricing = response.data.pricing
          this.number_of_players = 0
          this.pricing_categories = []

          for(let n in this.pricing){
            if(!(_.find(this.pricing_categories, { category_id: this.pricing[n].category_id }))){
              this.pricing_categories.push({
                category_id: this.pricing[n].category_id,
                category: this.pricing[n].category.toLowerCase(),
                number_of_players: this.pricing[n].default_participants,
                price: this.pricing[n].price,
                minimum_participants: this.pricing[n].minimum_participants
              })
              this.number_of_players++
            }
          }
          this.getGiftCardGamePrice()
          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getGiftCardAmount(amount){
      if(amount === 'Custom value' || amount === 'Value of a game'){
        return amount
      }
      return this.getCurrencySymbol(this.locale.currency) + this.getAmount(amount) + this.getCurrencyCode(this.locale.currency)
    },
    getRate(item){
      if(this.price_settings.cumulative_category_pricing){
        for(let n in this.pricing){
          if(this.pricing[n].category_id === item.category_id
            && this.number_of_players >= this.pricing[n].min_players
            && this.number_of_players <= this.pricing[n].max_players) {
            return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) + this.getAmount(this.pricing[n].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
              : this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[n].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + this.pricing[n].min_players + '-' + this.pricing[n].max_players + ' ' + item.category

          }
        }

        let last_index = _.findLastKey(this.pricing, {category_id: item.category_id})
        if(this.number_of_players > this.pricing[last_index].max_players){
          return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[last_index].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
            : this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[last_index].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + this.pricing[last_index].min_players + '-' + this.pricing[last_index].max_players + ' ' + item.category
        }

        let smallest_price_category = _.find(this.pricing, {category_id : item.category_id})
        if(this.number_of_players !== 0 &&
          this.number_of_players < smallest_price_category.min_players){
          return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) + this.getAmount(smallest_price_category.price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
            : this.getCurrencySymbol(this.locale.currency) + this.getAmount(smallest_price_category.price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + smallest_price_category.min_players + '-' + smallest_price_category.max_players + ' ' + item.category
        }
      } else {
        for(let n in this.pricing){
          if(this.pricing[n].category_id === item.category_id
            && item.number_of_players >= this.pricing[n].min_players
            && item.number_of_players <= this.pricing[n].max_players) {
            return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) + this.getAmount(this.pricing[n].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
              : this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[n].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + item.category + ' (' + this.pricing[n].min_players + '-' + this.pricing[n].max_players + this.$t('book.playersRate') + ')'

          }
        }

        let last_index = _.findLastKey(this.pricing, {category_id: item.category_id})
        if(item.number_of_players > this.pricing[last_index].max_players){
          return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[last_index].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
            : this.getCurrencySymbol(this.locale.currency) +  this.getAmount(this.pricing[last_index].price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + item.category + ' (' + this.pricing[last_index].min_players + '-' + this.pricing[last_index].max_players + this.$t('book.playersRate') + ')'
        }

        let smallest_price_category = _.find(this.pricing, {category_id : item.category_id})
        if(item.number_of_players !== 0 &&
          item.number_of_players < smallest_price_category.min_players){
          return this.price_settings.pricing_type_id === 1 ? this.getCurrencySymbol(this.locale.currency) + this.getAmount(smallest_price_category.price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.per') + item.category
            : this.getCurrencySymbol(this.locale.currency) + this.getAmount(smallest_price_category.price) + ' ' + this.getCurrencyCode(this.locale.currency) + this.$t('book.for') + item.category + ' (' + smallest_price_category.min_players + '-' + smallest_price_category.max_players + this.$t('book.playersRate') + ')'
        }
      }

      return ''
    },
    removeGiftCard(item){
      _.remove(this.applied_gift_cards, {
        id: item.id
      });
      this.pc_key++
      this.computeDeposit()
      this.setDisableSubmit()
    },
    removePromoCode(item){
      _.remove(this.promo_codes, {
        name: item.name
      });
      this.pc_key++
      this.computeDeposit()
      this.setDisableSubmit()
    },
    async applyPromo(){
      let bookings = []
      for(let n in this.cart){
        if(this.cart[n].hasOwnProperty('gift_card')){
          bookings.push({type: 'gift_card', subtotal: this.cart[n].subtotal})
        }else{
          bookings.push({id: this.cart[n].booking.id, booking_date: this.cart[n].booking.booking_date,
            start_time:  this.cart[n].booking.start_time,
            game_id: this.cart[n].game.game_id, subtotal: this.cart[n].subtotal,
            group_size: this.cart[n].quantity})
        }
      }
      let today = new Date()
      today = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);
      this.loading = true
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/promos?code=' + this.promo_code +
        '&date=' + today + '&bookings=' + encodeURIComponent(JSON.stringify(bookings))

      this.axios.get(url)
        .then(response => {
          this.loading = false
          if(JSON.stringify(response.data.promo_code) === '{}'){
            swal({
              title: this.$t('book.swals.error'),
              text: this.$t('book.swals.invalidPromoCode'),
              type: 'error',
              confirmButtonColor: '#1d8cf8',
              confirmButtonText: 'OK'
            })
            this.computeDeposit()
            return
          } else {
            if(parseFloat(response.data.promo_code.value_amount) <= 0){
              swal({
                title: this.$t('book.swals.error'),
                text: this.$t('book.swals.promoCodeCannotBeApplied'),
                type: 'error',
                confirmButtonColor: '#1d8cf8',
                confirmButtonText: 'OK'
              })
              return
            }
            this.promo_code = ''
            let dupe = false
            for(let n in this.promo_codes){
              if(this.promo_codes[n].name === response.data.promo_code.name){
                dupe = true
              }
              if(this.promo_codes[n].is_combineable === 0 || response.data.promo_code.is_combineable === 0){
                swal(this.$t('book.swals.error'), this.$t('book.swals.nonCombineablePromo'), 'error')
                return
              }
            }
            if(!dupe){
              let value_amount = parseFloat(response.data.promo_code.value_amount)
              if(value_amount > this.subtotal && response.data.promo_code.redemption_type === 1 ){
                value_amount = this.subtotal
              }

              this.promo_codes.push(
                {
                  id: response.data.promo_code.id,
                  name: response.data.promo_code.name,
                  value_amount: value_amount,
                  is_combineable: response.data.promo_code.is_combineable,
                  redemption_type: response.data.promo_code.redemption_type
                }
              )
            }
          }
          this.computeDeposit()
          // if(this.getTotalAmount() <= 0){
          //   this.disable_submit = false
          // }
          if(this.due_now <= 0){
            this.disable_submit = false
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    goToCallToBook(s){
      window.open(s.external_link, '_blank').focus();
    },
    scrollToTop(){
      const root = document.getElementById('root');
      root.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    rowsToAdd(game){
      let rows = Math.ceil(this.max_time_slots/3) - Math.ceil(this.getOrderedTimeSlots(game.slots).length/3)
      return rows
    },
    getCurrencyCode(code){
      if(this.locale.currency_format === 1){
        return code
      }
      return ''
    },
    getCurrencySymbol(code){
      if(this.locale.currency_format === 0){
        let c = _.find(this.currencies, { code: code });
        return c.symbol
      }
      return ''
    },
    showPrevious(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      let today = new Date()
      return current > today
    },
    decrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() - 1)
      this.currentDay = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2)
      this.getSchedules()
    },
    decrementWeek(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() - 7)
      this.currentDay = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2)
      this.getSchedules()
    },
    incrementWeek(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() + 7)
      this.currentDay = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2)
      this.getSchedules()
    },
    incrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() + 1)
      this.currentDay = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2)
      this.getSchedules()
    },
    handleEventClick(clickInfo) {
      if(clickInfo.event.title !== this.$t('book.available')){
        return
      }
      this.tabOne(1, this.selectedGame, clickInfo.event.extendedProps.bookingSlot)
    },
    showCalendar(game){
      this.selectedGame = game
      this.show_calendar = true
      setTimeout(()=>{
        let calendarApi = this.$refs.calendar.getApi();
        calendarApi.gotoDate(this.currentDay);
        }, 100);
    },
    disabledDates(time) {
      var date = new Date();
      var previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    getOrderedTimeSlots(slots){
      let s = []

      if(this.bss.show_unavailable_slots === 0){
        for(let n in JSON.parse(slots)){
          if(JSON.parse(slots)[n].status != 'booked' && JSON.parse(slots)[n].status != 'blocked'){
            s.push(JSON.parse(slots)[n])
          }
        }
      } else {
        s = JSON.parse(slots)
      }

      if(s === null){
        s = []
      }
      // let displayedSlots = []
      let blocked_days = 0
      let blocked_minutes = 0
      let slot_date = new Date()
      //Don't show min period
      for(let n in s) {
        slot_date.setFullYear(s[n].booking_date.split('-')[0])
        slot_date.setMonth(parseInt(s[n].booking_date.split('-')[1]) - 1)
        slot_date.setDate(s[n].booking_date.split('-')[2])
        slot_date.setHours(s[n].start_time.split(':')[0])
        slot_date.setMinutes(s[n].start_time.split(':')[1])
        blocked_days = 0
        blocked_minutes = 0
        let unblock_date = new Date()
        if (s[n].min_booking_period === 0) {
          blocked_minutes = s[n].min_booking_value
          unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
        } else if (s[n].min_booking_period === 1) {
          blocked_minutes = parseInt(s[n].min_booking_value) * 60
          unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
        } else if (s[n].min_booking_period === 2) {
          blocked_days = s[n].min_booking_value
          unblock_date.setDate(unblock_date.getDate() + blocked_days)
        } else if (s[n].min_booking_period === 3) {
          blocked_days = 7 * parseInt(s[n].min_booking_value)
          unblock_date.setDate(unblock_date.getDate() + blocked_days)
        } else if (s[n].min_booking_period === 4) {
          //months
          const currentDate = new Date(); // Get the current date
          const futureDate = new Date(currentDate.getTime());

          futureDate.setMonth(futureDate.getMonth() + parseInt(s[n].min_booking_value));
          const timeDifference = futureDate.getTime() - currentDate.getTime();
          blocked_days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          unblock_date.setDate(unblock_date.getDate() + blocked_days)
        } else if (s[n].min_booking_period === null) {
          unblock_date = new Date()
        }

      let  max_days = 0

      //calculate the number of days in the future to make booking slots for
      if(s[n].max_booking_period === 0){
        max_days = parseInt(s[n].max_booking_value)
      } else if (s[n].max_booking_period === 1){
        max_days = parseInt(s[n].max_booking_value) * 7
      } else if (s[n].max_booking_period === 2){
        let future = new Date()
        let today = new Date()
        future.setMonth(future.getMonth() + parseInt(s[n].max_booking_value))
        let diffTime = Math.abs(future - today)
        max_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      } else if (s[n].max_booking_period === 3){
        let future = new Date()
        let today = new Date()
        future.setFullYear(future.getFullYear() + parseInt(s[n].max_booking_value))
        let diffTime = Math.abs(future - today)
        max_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      } else if (s[n].max_booking_period === null){
        max_days = 10
      }

        let max_date = new Date()
        max_date.setDate(max_date.getDate() + max_days)

        if(slot_date <= unblock_date){
          s[n].block = true
        }
         else {
          s[n].block = false
        }

        if(slot_date >= max_date) {
          s[n].remove = true
        } else {
          s[n].remove = false
        }

      }

      if(s.length > this.max_time_slots){
        this.max_time_slots = s.length
      }
      return _.orderBy(s, 'start_time')
    },
    goToCheckIn(){
      let code = this.$route.params.company
      let path = '/checkin/' + code
      if(this.bss.display_waiver_link){
        path = '/waiver/' + this.hash
      }
      this.$router.push(path)
    },
    goHome(){
      window.location.href = this.bss.redirect_url
    },
    getDate(d){
      return date(d)
    },
    getTime(format, d){
      return time(format, d)
    },
    toProfile(){
      this.$router.push('/settings/profile')
    },
    login(){
      let redirect = '/book/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/login')
    },
    async isAuthenticated(){
      let token = await this.getAuthToken()
      if(token == 'No current user'){
        this.is_authenticated = false
      } else {
        this.is_authenticated = true
        this.user = JSON.parse(localStorage.getItem('user'))
      }
    },
    getFormattedDate(format, d){
      return formattedDate(format, d)
    },
    getDayOfWeek(d){
      if(this.locale.show_day_of_week){
        return dayOfWeek(d)
      }
      return ''
    },
    async removeBooking(index, item){
      this.cart.splice(index, 1)

      //Cart event
      let booking_slot_id = item.booking.id
      this.removeFromCart(booking_slot_id)
      //Pay on arrival or deposit
      this.computeDeposit()
      await this.recalculatePromos()
    },
    recalculatePromos(source){
      if(source === 'deposit'){
        this.applied_gift_cards = []
      }
      if(this.cart.length <= 0){
        this.promo_codes = []
      } else {
        let bookings = []
        let today = new Date()
        if(this.promo_codes.length <= 0){
          this.computeDeposit()
        }
        for(let m in this.promo_codes){
          bookings = []
          for(let n in this.cart){
            if(this.cart[n].hasOwnProperty('gift_card')){
              bookings.push({type: 'gift_card', subtotal: this.cart[n].subtotal})
            }else{
              bookings.push({id: this.cart[n].booking.id, booking_date: this.cart[n].booking.booking_date,
                start_time:  this.cart[n].booking.start_time,
                game_id: this.cart[n].game.game_id, subtotal: this.cart[n].subtotal,
                group_size: this.cart[n].quantity})
            }
          }

          today = new Date()
          today = today.getFullYear() +  "-" +  ('0' + (today.getMonth() + 1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2);
          this.loading = true
          let name = this.promo_codes[m].name

          let code = this.$route.params.company
          let url = API_LOCATION + 'bookings/company-groups/' + code + '/promos?code=' + name +
            '&date=' + today + '&bookings=' + encodeURIComponent(JSON.stringify(bookings))

          this.promo_codes.splice(m, 1)
          this.axios.get(url)
            .then(response => {
              this.loading = false
              if(JSON.stringify(response.data.promo_code) === '{}'){
                swal({
                  title: this.$t('book.swals.error'),
                  text: this.$t('book.swals.invalidPromoCode'),
                  type: 'error',
                  confirmButtonColor: '#1d8cf8',
                  confirmButtonText: 'OK'
                })
                this.computeDeposit()
                return
              } else {
                if(parseFloat(response.data.promo_code.value_amount) <= 0){
                  swal({
                    title: this.$t('book.swals.error'),
                    text: this.$t('book.swals.promoCodeCannotBeApplied'),
                    type: 'error',
                    confirmButtonColor: '#1d8cf8',
                    confirmButtonText: 'OK'
                  })
                  return
                }
                this.promo_code = ''
                let dupe = false
                for(let n in this.promo_codes){
                  if(this.promo_codes[n].name === response.data.promo_code.name){
                    dupe = true
                  }
                  if(this.promo_codes[n].is_combineable === 0 || response.data.promo_code.is_combineable === 0){
                    swal(this.$t('book.swals.error'), this.$t('book.swals.nonCombineablePromo'), 'error')
                    return
                  }
                }
                if(!dupe){
                  let value_amount = parseFloat(response.data.promo_code.value_amount)
                  if(this.getTotalAmount() < value_amount){
                    value_amount = this.getTotalAmount()
                  }
                  this.promo_codes.push(
                    {
                      id: response.data.promo_code.id,
                      name: response.data.promo_code.name,
                      value_amount: value_amount,
                      is_combineable: response.data.promo_code.is_combineable
                    }
                  )
                }
              }
              this.computeDeposit()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              if(err.response.status == 401){
                this.$router.push('/forbidden')
              }
            })
        }
      }
    },
    getNumberFormat(value){
      if(this.locale.number_format === 1){
        value = value.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      }
      return value
    },
    getCartSubtotal(){
      let subtotal = 0
      for(let n in this.cart){
        subtotal += parseFloat(this.cart[n].subtotal)
      }
      return this.getNumberFormat(subtotal.toFixed(2))
    },
    getFee(amount, value_type,){
      let subtotal = 0
      let total_number_of_players = 0
      for(let n in this.cart){
        subtotal += parseFloat(this.cart[n].subtotal)
        if(!this.cart[n].hasOwnProperty('gift_card')){
          total_number_of_players += this.cart[n].quantity
        }
      }
      if(value_type === 0){
        return amount
      }
      if(value_type === 1){
        return amount * total_number_of_players
      }
      if(value_type === 2){
        return this.getPercentage(amount, subtotal)
      }
    },
    hasBookingsInCart(){
      let bookings_count = 0
      for(let n in this.cart){
        if(!this.cart[n].hasOwnProperty('gift_card')){
          bookings_count++
        }
      }
      return bookings_count > 0 ? true : false
    },
    getTax(amount, value_type, is_compound, apply_after_discount){
      let taxable_subtotal = 0
      let total_number_of_players = 0
      let bookings_in_cart = 0
      for(let n in this.cart){
        if(!this.cart[n].hasOwnProperty('gift_card')){
          taxable_subtotal += parseFloat(this.cart[n].subtotal)
          total_number_of_players += this.cart[n].quantity
          bookings_in_cart++
        }
      }
      if(value_type === 0 && bookings_in_cart > 0){
        return amount
      }
      if(value_type === 1){
        return amount * total_number_of_players
      }
      if(value_type === 2 && !is_compound){
        if(apply_after_discount === 1){
          let after_discount = taxable_subtotal - this.getDiscounts()
          if(after_discount <= 0){
            amount = 0
          }

          return this.getPercentage(amount, after_discount)
        } else {
          return this.getPercentage(amount, taxable_subtotal)
        }
      }
      if(value_type === 2 && is_compound){
        if(apply_after_discount === 1){
          let after_discount = this.getCompoundSubtotal() - this.getDiscounts()
          if(after_discount <= 0){
            amount = 0
          }

          return this.getPercentage(amount, after_discount)
        } else {
          return this.getPercentage(amount, this.getCompoundSubtotal())
        }
      }
      return 0
    },
    getPercentage(amount, subtotal){
      if(subtotal && subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }
      if(amount && amount.toString().includes(',')){
        amount = amount.replace(',', '.')
      }
      return parseFloat(subtotal) * (parseFloat(amount)*0.01)
    },
    getCompoundSubtotal(){
      let subtotal = 0

      for(let n in this.cart){
        if(!this.cart[n].hasOwnProperty('gift_card')){
          subtotal += parseFloat(this.cart[n].subtotal)
        }
      }
      for(let t in this.taxes){
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0){
            subtotal = subtotal + this.taxes[t].amount
          }
          //value (per person)
          if(this.taxes[t].value_type === 1){
            subtotal = subtotal + (this.taxes[t].amount * this.number_of_players)
          }
          //percentage
          if(this.taxes[t].value_type === 2){
            //not compound
            if(!this.taxes[t].is_compound){
              subtotal = subtotal + this.getPercentage(this.taxes[t].amount, subtotal)
            }
          }
        }
      }
      return subtotal
    },
    getTotalAmount(){
      let total = 0
      let taxable_total = 0
      let total_number_of_players = 0
      let bookings_in_cart = 0
      for(let n in this.cart){
        total += parseFloat(this.cart[n].subtotal)
        if(!this.cart[n].hasOwnProperty('gift_card')){
          taxable_total += parseFloat(this.cart[n].subtotal)
          total_number_of_players += this.cart[n].quantity
          bookings_in_cart++
        }
      }

      let total_taxes = 0
      let total_fees = 0
      let total_discount = 0
      //add taxes

      for(let t in this.taxes){
        //exclusive of price
        if(this.taxes[t].pricing_type === 0){
          //value (total)
          if(this.taxes[t].value_type === 0 && bookings_in_cart > 0){
            total_taxes += this.taxes[t].amount
            taxable_total += total_taxes
          }
          //value (per person)
          if(this.taxes[t].value_type === 1 && bookings_in_cart > 0){
            total_taxes += (this.taxes[t].amount * total_number_of_players)
            taxable_total += total_taxes
          }
          //percentage
          if(this.taxes[t].value_type === 2 && bookings_in_cart > 0){
            //not compound
            let amount = this.taxes[t].amount
            if(this.taxes[t].is_compound){
              if(this.taxes[t].apply_after_discount){
                let after_discount = taxable_total - this.getDiscounts()
                if(after_discount <= 0){
                  amount = 0
                }
                total_taxes += this.getPercentage(amount, after_discount)
                taxable_total += total_taxes
              } else {
                total_taxes += this.getPercentage(amount, taxable_total)
                taxable_total += total_taxes
              }

            } else {
              if(this.taxes[t].apply_after_discount){
                let after_discount = total - this.getDiscounts()
                if(after_discount <= 0){
                  amount = 0
                }
                total_taxes += this.getPercentage(amount, after_discount)
                taxable_total += total_taxes
              } else {
                total_taxes += this.getPercentage(amount, taxable_total)
                taxable_total += total_taxes
              }
            }
          }
        }
      }

      for(let n in this.fees){
        //value (total)
        if(this.fees[n].value_type === 0){
          total_fees += this.fees[n].amount
        }
        //value (per person)
        if(this.fees[n].value_type === 1){
          total_fees += (this.fees[n].amount * total_number_of_players)
        }
        //percentage
        if(this.fees[n].value_type === 2){
          total_fees += this.getPercentage(this.fees[n].amount, this.getCartSubtotal())
        }
      }
      total = total + total_taxes + total_fees + parseFloat(this.applied_tip)

      for(let n in this.promo_codes){
        total_discount = parseFloat(total_discount) + parseFloat(this.promo_codes[n].value_amount)
        if(total_discount > total){
          //get the value of taxes that are applied after discount
          let apply_after_discount_taxes = 0
          for(let n in this.taxes){
            if(this.taxes[n].apply_after_discount === 1){
              apply_after_discount_taxes += parseFloat(this.getTax(this.taxes[n].amount, this.taxes[n].value_type, this.taxes[n].is_compound, this.taxes[n].apply_after_discount))
            }
          }
          total_discount = total + total_taxes + total_fees - apply_after_discount_taxes
        }
      }

      total = total - total_discount

      this.payment.amount = total
      //this.getNumberFormat(total.toFixed(2))
      return total
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    tabZero(index, source){
      this.gift_card_select = false
      this.scrollToTop()
      if(source === 1){
        this.show_calendar = false
      }
      this.$refs['wiz'].tabIndex(index);
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
    },
    giftCardSelect(){
      this.addToCart(4)
      this.scrollToTop()
      this.gift_card_select = true
      this.gift_card = {
          personal_message: '',
          design_url: '',
          custom_amount: '',
          recipient_first_name: '',
          recipient_last_name: '',
          recipient_email: '',
          total: 0,
          quantity: 1,
          send_instantly: true,
          delivery_date: new Date()
      }
      this.buttonGroupStyle = [{ 'font-family': this.bss.font_family_card,
        'border-color': this.bss.button_color,
        'background-color': this.bss.button_color,
        'background-image': 'linear-gradient(to bottom left,' + this.bss.button_color + ', ' + this.bss.button_color + ', ' + this.bss.button_color + ') !important',
        'color':  this.bss.card_color}, { 'font-family': this.bss.font_family_card,
        'border-color': this.bss.button_color, 'color': this.bss.button_color }]
      this.buttonGroupPriceStyle = [{ 'font-family': this.bss.font_family_card,
        'border-color': this.bss.button_color,
        'background-color': this.bss.button_color,
        'background-image': 'linear-gradient(to bottom left,' + this.bss.button_color + ', ' + this.bss.button_color + ', ' + this.bss.button_color + ') !important',
        'color':  this.bss.card_color}, { 'font-family': this.bss.font_family_card,
        'border-color': this.bss.button_color, 'color': this.bss.button_color }]
      this.getGiftCardSettings()
      this.$refs['wiz'].tabIndex(1);
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
    },
    getGiftCardSettings(){
      this.loading = true
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/gift-cards'
      axios.get(url)
        .then(response => {
          this.loading = false
          this.gift_card_settings = response.data.gift_card_settings
          this.gift_card_designs = response.data.designs
          if(this.gift_card_settings.gift_cards_allow_preset_values){
            this.gift_card_amounts = response.data.amounts
          }

          if(this.gift_card_settings.gift_cards_allow_arbitrary_values && !(_.find(this.gift_card_amounts, {id: 0}))){
            this.gift_card_amounts.push({
              amount: 'Custom value',
              id: 0
            })
          }

          if(this.gift_card_settings.gift_cards_allow_purchase_for_game && !(_.find(this.gift_card_amounts, {id: 1}))){
            this.gift_card_amounts.push({
              amount: 'Value of a game',
              id: 1
            })
          }

          this.selected_gift_card_amount = this.gift_card_amounts[0]
          this.gift_card.design_url = this.gift_card_designs[0]
          this.getGiftCardTotal()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    tabOne(index, game, slot){
      this.gift_card_select = false
      this.errors.number_of_players = ''
      this.scrollToTop()
      if(game && slot){
        this.selectedGame = game
        this.selectedBooking = slot
        this.number_of_players = this.selectedGame.min_players_count
        this.getPricing()
      }
      this.$refs['wiz'].tabIndex(index);
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
      this.addToCart(2)
    },
    isCardHolderValid(){
      let errors = 0

      if (this.payment.cardholder_last_name == '' && !this.pay_on_arrival) {
        this.errors.cardholder_last_name = this.$t('book.errors.lastName')
        errors++
      } else {
        this.errors.cardholder_last_name = ""
      }
      if (this.payment.cardholder_first_name == '' && !this.pay_on_arrival) {
        this.errors.cardholder_first_name = this.$t('book.errors.firstName')
        errors++
      } else {
        this.errors.cardholder_first_name = ""
      }
      if(errors > 0){
        return false
      }
      return true
    },
    async applyGiftCard(){
      if(!this.isCardHolderValid()){
        return
      }
      if(this.getTotalAmount() <= 0){
        swal({
          title: this.$t('book.swals.error'),
          text: this.$t('book.swals.cannotApplyGiftCard'),
          type: 'error'
        })
        return
      }
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/gift-cards'
      let data = {
        gift_card_code: this.gift_card_code,
        email: this.user.email
      }

      axios.post(url, data)
        .then(response => {
          this.gift_card_code = ''
          let existing_gift_card = _.find(this.applied_gift_cards, {id: response.data.gift_card.id })
          if(!existing_gift_card && this.due_now > 0){
            let cart_total = this.due_now
            if(response.data.gift_card.remaining > cart_total){
              response.data.gift_card.remaining = cart_total
            }
            this.applied_gift_cards.push(response.data.gift_card)
          }

          this.due_now = this.getDueNow()
          this.computeDeposit()
          if(this.due_now <= 0){
            this.due_now = 0
          }
          this.setDisableSubmit()
          this.loading = false

        })
        .catch(err => {
          swal({
            title: this.$t('book.swals.error'),
            text: this.$t('book.swals.invalidGiftCard'),
            type: 'error'
          })
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    validateGiftCard(){
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let errors = 0
      if(!this.gift_card.send_to_self && (this.gift_card.recipient_email == '' || (emailRegex.test(this.gift_card.recipient_email) == false))){
        this.errors.gift_card.email = this.$t('book.errors.email')
        errors++
      } else {
        this.errors.gift_card.email = ''
      }
      if(this.gift_card.recipient_first_name == '' && !this.gift_card.send_to_self){
        this.errors.gift_card.first_name = this.$t('book.errors.firstName')
        errors++
      } else {
        this.errors.gift_card.first_name = ''
      }
      if(!this.gift_card.send_to_self && this.gift_card.recipient_first_name == ''){
        this.errors.gift_card.last_name = this.$t('book.errors.lastName')
        errors++
      } else {
        this.errors.gift_card.last_name = ''
      }
      if(this.gift_card_settings.gift_cards_require_terms && !this.gift_card.terms){
        this.errors.gift_card.terms = this.$t('book.errors.terms')
        errors++
      } else {
        this.errors.gift_card.terms = ''
      }

      if(this.selected_gift_card_amount.amount === 'Custom value' && this.gift_card.custom_amount === undefined){
        this.errors.gift_card.custom_amount = this.$t('book.errors.value')
        errors++
      } else {
        this.errors.gift_card.custom_amount = ''
      }

      return errors > 0 ? false : true
    },
    async setGiftCard(){
      if(!this.validateGiftCard()){
        return
      }

      this.scrollToTop()
      this.cart.push({
        game: this.selectedGame,
        gift_card: {
          personal_message: this.gift_card.personal_message,
          design_url: this.gift_card.design_url,
          custom_amount: this.gift_card.custom_amount,
          recipient_first_name: this.gift_card.recipient_first_name,
          recipient_last_name: this.gift_card.recipient_last_name,
          recipient_email: this.gift_card.recipient_email,
          total: this.gift_card.total,
          quantity: this.gift_card.quantity,
          game_id: this.selectedGame.game_id,
          number_of_participants: this.number_of_players,
          send_to_self: this.gift_card.send_to_self,
          send_instantly: this.gift_card.send_instantly,
          delivery_date: this.gift_card.send_instantly ? null : this.gift_card.delivery_date
        },
        quantity: this.number_of_players,
        subtotal: this.gift_card.total,
        booking: '',
      })
      await this.recalculatePromos()
      if(window.fbq){
        fbq('track', 'AddToCart');
      }
      if(window.gtag) {
        if (window !== window.top) {
          let eventData = {
            eventType: 'add_to_cart',
            eventValue: this.gift_card.total,
            currency: this.locale.currency
          }
          this.$nextTick(() => {
            this.postMessageTrackingEvent(eventData)
          });
        } else {
          gtag("event", "add_to_cart", {
            currency: this.locale.currency,
            value: this.gift_card.total
          })
        }
      }

      this.$refs['wiz'].tabIndex(2)
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
    },
    async tabTwo(index){
      if(!this.validQuantity()){
        return
      }

      this.prevent_submit = false
      this.scrollToTop()
      // this.selectedBooking = booking
      let found = 0
      for(let n in this.cart){
        if(this.selectedBooking.id == this.cart[n].booking.id){
          this.cart[n].quantity = this.number_of_players
          this.cart[n].subtotal = this.subtotal
          this.cart[n].tickets = this.pricing_categories
          found++
        }
      }
      if(found <= 0){
        this.cart.push({
          game: this.selectedGame,
          price_settings: this.price_settings,
          booking: this.selectedBooking,
          quantity: this.number_of_players,
          subtotal: this.subtotal,
          tickets: this.pricing_categories
        })
      }
      await this.recalculatePromos()
      if(window.fbq){
        fbq('track', 'AddToCart');
      }
      if(window.gtag) {
        if (window !== window.top) {
          let eventData = {
            eventType: 'add_to_cart',
            eventValue: this.subtotal,
            currency: this.locale.currency
          }
          this.$nextTick(() => {
            this.postMessageTrackingEvent(eventData)
          });
        } else {
          gtag("event", "add_to_cart", {
            currency: this.locale.currency,
            value: this.subtotal
          })
        }
      }
      this.$refs['wiz'].tabIndex(index)
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
      this.addToCart(3, this.selectedBooking)
      // this.due_now = this.getDueNow()
      // this.due_later = this.getTotalAmount() - this.due_now
    },
    createCart(){
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/cart'

      axios.get(url)
        .then(response => {
          this.cart_id = response.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    removeFromCart(booking_id){
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/cart?id=' + booking_id + '&hash=' + this.cart_id

      axios.delete(url)
        .then(response => {
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    addToCart(step, booking){
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/cart'
      let data = {
        booking_slot_id: booking ? booking.id : null,
        cart_id: this.cart_id,
        cart_value: this.subtotal,
        current_step: step,
        user: this.user,
      }
      axios.post(url, data)
        .then(response => {
          this.loading = false

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    isEmptyCart(){
      if(this.cart.length <= 0){
        return true
      }
      return false
    },
    computeDeposit(){
      //check if deposit is required
      this.allow_deposit = false
      this.deposit = 0
      this.require_deposit = false
      for(let n in this.cart){
        if(!this.cart[n].hasOwnProperty('gift_card')
          && this.cart[n].price_settings.allow_deposit && this.cart[n].price_settings.require_deposit){
          this.require_deposit = true
          this.allow_deposit = true
          this.pay_deposit = true
        }
      }
      for(let n in this.cart) {
        if (this.cart[n].hasOwnProperty('gift_card')) {
          this.allow_pay_on_arrival = false
        } else {
          if(this.cart[n].price_settings.allow_deposit === 1){
            this.allow_deposit = true
            if(this.cart[n].price_settings.deposit_type === 0){
              this.deposit += parseFloat(this.cart[n].price_settings.deposit_amount)
            } else {
              this.deposit += parseFloat(this.cart[n].price_settings.deposit_amount) * this.cart[n].quantity
            }
          }
        }
        if(this.deposit > this.getTotalAmount()){
          this.deposit = this.getTotalAmount()
        }
      }

      for(let n in this.cart) {
        if(!this.cart[n].hasOwnProperty('gift_card') && this.cart[n].price_settings.allow_deposit === 0 && this.allow_deposit){
          this.deposit += parseFloat(this.cart[n].subtotal)
        }
      }
      if(!this.allow_deposit){
        this.pay_deposit = false
      }

      //set deposit rows if deposit has already been selected
      this.selectPayDeposit()
    },
    tabThree(index, source){
      this.disable_submit = true

      if(this.selectedPaymentOption.name === 'Card' &&
        (this.payment_settings.payment_gateway_id === 2 || this.payment_settings.payment_gateway_id === 1
          || this.payment_settings.payment_gateway_id === 4)){
        this.disable_submit = false
      }
      if(this.selectedPaymentOption.name !== 'Card'
        && this.selectedPaymentOption.name !== 'Gift card'){
        this.disable_submit = false
      }
      if(this.isEmptyCart()){
        swal({
          title: this.$t('book.swals.emptyCart'),
          text: this.$t('book.swals.addItemsToCart'),
          type: 'warning'
        })
        return
      }
      this.scrollToTop()
      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let errors = 0
      if(this.user.first_name == ''){
        this.errors.first_name = this.$t('book.errors.firstName')
        errors++
      } else {
        this.errors.first_name = ''
      }
      if(this.user.last_name == ''){
        this.errors.last_name =  this.$t('book.errors.lastName')
        errors++
      } else {
        this.errors.last_name = ''
      }
      if(this.user.email == '' || (emailRegex.test(this.user.email) == false)){
        this.errors.email = this.$t('book.errors.email')
        errors++
      } else {
        this.errors.email = ''
      }
      if(this.user.phone == ''){
        this.errors.phone = this.$t('book.errors.phone')
        errors++
      } else {
        this.errors.phone = ''
      }

      for(let n in this.custom_fields){
        if(this.custom_fields[n].required && this.isFieldVisibile(this.custom_fields[n]) && !this.custom_fields[n].customer_response){
          this.custom_fields[n].error = this.$t('book.errors.requiredField')
          errors++
        } else {
          this.custom_fields[n].error = ''
        }
      }

      if(errors > 0){
        return
      }

      if(source === 1){
        this.completeBooking()
      } else {
        //Payment gateway
        if(this.payment_settings.payment_gateway_id === 3){
          this.initializeStripe()
        }
        if(this.payment_settings.payment_gateway_id === 2){
          this.initializeSquare()
        }

        this.computeDeposit()
      }

      this.addToCart(6)
      if(window.fbq){
        fbq('track', 'InitiateCheckout');
      }
      if(window.gtag) {
        if (window !== window.top) {
          let eventData = {
            eventType: 'begin_checkout',
            eventValue: this.total,
            currency: this.locale.currency
          }
          this.$nextTick(() => {
            this.postMessageTrackingEvent(eventData)
          });
        } else {
          gtag("event", "begin_checkout", {
            currency: this.locale.currency,
            value: this.total
          })
        }
      }
      this.$refs['wiz'].tabIndex(index)
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      }
    },
    tabFive() {
      if (this.isEmptyCart()) {
        swal({
          title: this.$t('book.swals.emptyCart'),
          text: this.$t('book.swals.addItemsToCart'),
          type: 'warning'
        })
        return
      }
      this.scrollToTop()

      if ((this.getTotalAmount() > 0 && this.due_now > 0) || this.applied_gift_cards.length > 0) {
        let errors = 0

        if(!this.isCardHolderValid()){
          errors++
        }
        if (this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 1 && !this.pay_on_arrival) {
          if (this.payment.cardNumber == '' || this.payment.cardNumber.length < 19) {
            this.errors.ccn = true
            errors++
          } else {
            this.errors.ccn = false
          }
          if (this.payment.zip == '' && !this.pay_on_arrival) {
            this.errors.zip = true
            errors++
          } else {
            this.errors.zip = false
          }
          if (this.payment.expirationDate == '' && !this.pay_on_arrival) {
            this.errors.expiration = true
            errors++
          } else {
            this.errors.expiration = false
          }
          if (this.payment.cardCode == '' || this.payment.cardCode < 3 && !this.pay_on_arrival) {
            this.errors.cvv = true
            errors++
          } else {
            this.errors.cvv = false
          }
        }

        if (errors > 0) {
          return
        }
      }

      if (this.selectedPaymentOption.name === 'Yappy' && this.payment_settings.payment_gateway_id === 5
        && !this.pay_on_arrival){
        this.payWithYappy()
        return
      }

      this.completeBooking()
    },
    disableTabsTest(){
      this.$refs['wiz'].disableTabs();
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        this.disable_submit = false
        throw new Error(errorMessage);
      }
    },
    async initializeCard(payments) {
      const customStyle = {
        '.input-container.is-focus': {
          borderColor: '#e14eca',
        },
        '.input-container.is-error': {
          borderColor: '#ff1600',
        },
        '.message-icon': {
          color: this.bss.text_color_card,
        },
        '.message-text.is-error': {
          color: '#ff1600',
        },
        '.message-icon.is-error': {
          color: '#ff1600',
        },
        'input.is-error': {
          color: '#ff1600',
        },
        '@media screen and (max-width: 600px)': {
          'input': {
            'fontSize': '12px',
          }
        },
        '.input-container': {
            borderColor: this.bss.text_color_card,
            borderRadius: '6px',
          },
          '.message-text': {
            color: this.bss.text_color_card,
          },
          input: {
            backgroundColor: this.bss.card_color,
            color: this.bss.text_color_card,
            fontFamily: 'helvetica neue, sans-serif',
          },
          'input::placeholder': {
            color: this.bss.text_color_card,
          },
        }
      const card = await payments.card({
        style: customStyle,
      });
      await card.attach('#card-container');
      return card;
    },
    async verifyBuyer(payments, token) {
      const verificationDetails = {
         amount: this.payment.amount.toString(),
        /* collected from the buyer */
        billingContact: {
          familyName: this.payment.cardholder_last_name,
          givenName: this.payment.cardholder_first_name,
        },
        currencyCode: this.locale.currency,
        intent: 'CHARGE',
      };

      const verificationResults = await payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async initializeSquare(){
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }
      if(this.card){
        const destroyed = await this.card.destroy()
      }
      this.square = window.Square.payments(this.squareAppId, this.squareLocationId);
      this.square.setLocale(this.$i18n.locale)
      this.loading = true
      try {
        this.card = await this.initializeCard(this.square);
        this.loading = false
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    },
    colorToHex(color) {
      if (color.startsWith('#')) {
        if (color.length === 4) {
          return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
        }
        if (color.length === 7) {
          return color;
        }
      }

      const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i);

      if (!match) {
        throw new Error("Invalid color format");
      }

      const r = parseInt(match[1]).toString(16).padStart(2, '0');
      const g = parseInt(match[2]).toString(16).padStart(2, '0');
      const b = parseInt(match[3]).toString(16).padStart(2, '0');

      return `#${r}${g}${b}`;
    },
    async initializeStripe(){
      this.stripe = window.Stripe(this.payment_settings.api_name, {
                    locale: this.$i18n.locale
                  });
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/payment/stripe-payment-intent'

      let data = {
        currency: this.locale.currency,
        total: this.getTotalAmount()
      }

      this.loading = true
      axios.post(url, data)
        .then(response => {
          this.loading = false
          let clientSecret = response.data.clientSecret
          this.payment_intent_id = response.data.clientSecret.split('_')[0] + '_' + response.data.clientSecret.split('_')[1]
          const appearance = {
            theme: 'stripe',
            variables: {
              colorPrimary: this.colorToHex(this.bss.button_color),
              colorBackground: this.colorToHex(this.bss.card_color),
              colorText: this.colorToHex(this.bss.text_color_card),
            },
          };
          this.elements = this.stripe.elements({ appearance, clientSecret });
          const paymentElementOptions = {
            layout: "tabs",
          };

          const paymentElement = this.elements.create("payment", paymentElementOptions);
          paymentElement.mount("#payment-element");

          paymentElement.on('change', (event) => {
            if (event.complete) {
              // Enable the payment button
             this.disable_submit = false;
            } else {
              // Disable the payment button
              if(this.selectedPaymentOption.name === 'Card'){
                this.disable_submit = true;
              }
            }
          });
        })
        .catch(err => {
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    getDiscounts(){
      let total_discount = 0
      for(let n in this.promo_codes){
        total_discount = parseFloat(total_discount) + parseFloat(this.promo_codes[n].value_amount)
      }
      return total_discount
    },
    async completeBooking(){
      this.addToCart(7)
      this.disable_submit = true
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/payment'
      this.payment.cardNumber = this.payment.cardNumber.replace(/\s/g, '');
      let year = '20' + this.payment.expirationDate.split('/')[1]
      let month = this.payment.expirationDate.split('/')[0]
      this.payment.expirationDate = year + '-' + month

      let subtotal = this.getCartSubtotal()
      if(subtotal.toString().includes(',')){
        subtotal = subtotal.replace(',', '.')
      }

      let bookings =  []
      let gift_cards = []
      for(let n in this.cart){
        if(this.cart[n].hasOwnProperty('gift_card')){
          if(this.cart[n].gift_card.send_to_self){
            this.cart[n].gift_card.recipient_first_name = this.user.first_name
            this.cart[n].gift_card.recipient_last_name = this.user.last_name
            this.cart[n].gift_card.recipient_email = this.user.email
          }
          gift_cards.push(this.cart[n])
        } else {
          bookings.push(this.cart[n])
        }
      }
      let custom_fields = []
      let resp = ''
      for(let n in this.custom_fields){
        resp = this.custom_fields[n].customer_response
        if(this.custom_fields[n].type === 3 && this.custom_fields[n].customer_response){
          if(this.custom_fields[n].customer_response.has_follow_up_field){
            resp = this.custom_fields[n].customer_response.name + ': ' + this.custom_fields[n].customer_response.follow_up_response
          } else {
            resp = this.custom_fields[n].customer_response.name
          }
        }
        custom_fields.push({
          customer_response: resp,
          id: this.custom_fields[n].id,
          type: this.custom_fields[n].type
        })
      }
      let data = {
        cart: this.cart_id,
        tip: this.roundToFixed(this.applied_tip, 2),
        bookings: bookings,
        gift_cards: gift_cards,
        user: this.user,
        discount: this.getDiscounts(),
        promo_codes: this.promo_codes,
        total: this.roundToFixed(this.payment.amount, 2),
        subtotal: subtotal,
        currency: this.locale.currency,
        deposit: this.pay_deposit ? this.deposit : null,
        paid_deposit: this.pay_deposit,
        custom_fields: custom_fields,
        applied_gift_cards: this.applied_gift_cards,
        locale: this.$i18n.locale
      }
      if(this.payment_intent_id){
        data.paymentIntent = this.payment_intent_id
      }
      if(this.selectedPaymentOption.name !== 'Gift card' && this.selectedPaymentOption.name !== 'Card'){
        data.payment_method = this.selectedPaymentOption
        data.cardholder_first_name = this.payment.cardholder_first_name
        data.cardholder_last_name = this.payment.cardholder_last_name
      }

      data.pay_on_arrival = this.pay_on_arrival
      let payment = {
        cardholder_first_name: this.payment.cardholder_first_name,
        cardholder_last_name: this.payment.cardholder_last_name
      }
      data.payment = payment

      if((data.total > 0 && !this.pay_deposit) || (data.deposit > 0 && this.pay_deposit)){
        if(this.allow_pay_on_arrival && this.pay_on_arrival){
          data.pay_on_arrival = true
        } else if(this.selectedPaymentOption.name === 'Card') {
          if (this.payment_settings.payment_gateway_id === 2) {
            //Square
            const token = await this.tokenize(this.card);
            //SCA
            let verificationToken = await this.verifyBuyer(
              this.square,
              token
            );
            data.payment.locationId = this.squareLocationId
            data.payment.sourceId = token
            data.payment.verificationToken = verificationToken
          } else if (this.payment_settings.payment_gateway_id === 1){
            data.payment.cardNumber = this.payment.cardNumber
            data.payment.expirationDate = this.payment.expirationDate
            data.payment.amount = this.roundToFixed(this.payment.amount, 2)
            data.payment.zip = this.payment.zip
            data.payment.cardCode = this.payment.cardCode
          }
          else if (this.payment_settings.payment_gateway_id === 4){
            this.paystack_reference = ''+Math.floor((Math.random() * 1000000000) + 1)
            data.paystack_reference = this.paystack_reference
          }
        }
      }

      if(this.prevent_submit == false){
        this.loading = true
        axios.post(url, data)
          .then(response => {
            if(this.pay_deposit){
              let paid = this.due_now
              for(let n in this.applied_gift_cards){
                paid += this.applied_gift_cards[n].remaining
              }
              this.due_now = paid
            } else if(this.pay_on_arrival){
              this.due_now = 0
              this.due_later = this.getTotalAmount()
            }
            else {
              this.due_now = this.getTotalAmount()
              this.due_later = 0
            }
            if(this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 3 && !this.pay_on_arrival && parseFloat(data.total) > 0){
              //stripe confirm payment goes here
              let elements = this.elements
              let self = this
              this.stripe.confirmPayment({
                elements,
                redirect: 'if_required',
                confirmParams: {
                  // Make sure to change this to your payment completion page
                  return_url: "https://offthecouch.io/",
                },
              }).then(function(result) {
                if (result.error) {
                  // Inform the customer that there was an error.
                  swal({
                    title: this.$t('book.swals.transactionFailed'),
                    text: this.$t('book.swals.declinedCard'),
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#fd5d93',
                    confirmButtonText: this.$t('book.swals.backToCart')
                  })
                  self.loading = false
                  self.prevent_submit = true
                  self.disable_submit = false
                } else {
                  self.loading = false
                  self.prevent_submit = true
                  self.disable_submit = false
                  if(window.fbq){
                    fbq('track', 'Purchase', {value: self.due_now, currency: self.locale.currency});
                  }
                  if(window.gtag) {
                    if (window !== window.top) {
                      let eventData = {
                        eventType: 'purchase',
                        eventValue: self.due_now,
                        currency: self.locale.currency
                      }
                      self.$nextTick(() => {
                        self.postMessageTrackingEvent(eventData)
                      });
                    } else {
                      gtag("event", "purchase", {
                        currency: self.locale.currency,
                        value: self.due_now
                      })
                    }
                  }

                  self.order_number = response.data.orderId
                  self.hash = response.data.hash
                  self.timestamp = response.data.timestamp
                  if(self.bss.auto_redirect){
                    let time = parseInt(this.bss.redirect_delay) * 1000
                    setTimeout(() => {
                      window.location.href = self.bss.redirect_url
                    }, time)
                  }
                  swal({
                    title: self.$t('book.swals.congratulations'),
                    text: self.$t('book.swals.confirmedBooking'),
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'OK'
                  }).then((response)=> {
                    self.$refs['wiz'].disableTabs();
                    self.$refs['wiz'].tabIndex(4)
                    if (window !== window.top) {
                      self.$nextTick(() => {
                        self.updateParentAboutHeightChange()
                      });
                    }
                    self.scrollToTop()
                    self.addToCart(9)
                    if(window.fbq){
                      fbq('track', 'Success');
                    }
                    if(window.gtag) {
                      if (window !== window.top) {
                        let eventData = {
                          eventType: 'success',
                          eventValue: self.due_now,
                          currency: self.locale.currency
                        }
                        self.$nextTick(() => {
                          self.postMessageTrackingEvent(eventData)
                        });
                      } else {
                        console.log({
                          currency: self.locale.currency,
                          value: self.due_now
                        })
                        gtag("event", "success", {
                          currency: self.locale.currency,
                          value: self.due_now
                        })
                      }
                    }
                  })
                }
              });

            } else if (this.selectedPaymentOption.name === 'Card' && this.payment_settings.payment_gateway_id === 4){

              let payment = this.payWithPaystack(bookings, custom_fields, gift_cards, subtotal)
              this.loading = false
            } else {
              this.loading = false
              if(window.fbq){
                fbq('track', 'Purchase', {value: this.due_now, currency: this.locale.currency});
              }
              if(window.gtag) {
                if (window !== window.top) {
                  let eventData = {
                    eventType: 'purchase',
                    eventValue: this.due_now,
                    currency: this.locale.currency
                  }
                  this.$nextTick(() => {
                    this.postMessageTrackingEvent(eventData)
                  });
                } else {
                  gtag("event", "purchase", {
                    currency: this.locale.currency,
                    value: this.due_now
                  })
                }
              }

              this.prevent_submit = true
              this.disable_submit = false
              this.order_number = response.data.orderId
              this.hash = response.data.hash
              this.timestamp = response.data.timestamp

              if(this.bss.auto_redirect){
                let time = parseInt(this.bss.redirect_delay) * 1000
                setTimeout(() => {
                  window.location.href = this.bss.redirect_url
                }, time)
              }
              swal({
                title: this.$t('book.swals.congratulations'),
                text: this.$t('book.swals.confirmedBooking'),
                type: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK'
              }).then((response)=> {
                this.$refs['wiz'].disableTabs();
                this.$refs['wiz'].tabIndex(4)
                if (window !== window.top) {
                  this.$nextTick(() => {
                    this.updateParentAboutHeightChange()
                  });
                }
                this.scrollToTop()
                this.addToCart(9)
                if(window.fbq){
                  fbq('track', 'Success');
                }
                if(window.gtag) {
                  if (window !== window.top) {
                    let eventData = {
                      eventType: 'success',
                      eventValue: this.due_now,
                      currency: this.locale.currency
                    }
                    this.$nextTick(() => {
                      this.postMessageTrackingEvent(eventData)
                    });
                  } else {
                    gtag("event", "success", {
                      currency: this.locale.currency,
                      value: this.due_now
                    })
                  }
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            this.disable_submit = false
            this.prevent_submit = false
            this.addToCart(8)
            if(err.response.data == 'This booking is no longer available'){
              swal({
                title: this.$t('book.swals.sorry'),
                text: this.$t('book.swals.bookingNotAvailable'),
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#fd5d93',
                confirmButtonText: this.$t('book.swals.startOver')
              }).then((response)=> {
                this.tabZero(0)
              })

            } else if (err.response.status == 500){

              swal({
                title: this.$t('book.swals.invalidTransaction'),
                text: err.response.data,
                type: 'error',
                showCancelButton: false,
                confirmButtonColor: '#fd5d93',
                confirmButtonText: this.$t('book.swals.backToBooking')
              }).then((response)=> {
                if(err.response.data === 'Your cart has expired'){
                  window.location.reload()
                } else {
                  this.tabZero(0)
                }

              })
            }
            if(err.response.status == 401){
              this.$router.push('/forbidden')
            }
          })
      }

    },
    async getBookingSlots(){
      //this.loading = true
      let dt = new Date()
      let calendarApi = this.$refs.calendar.getApi();
      const view = calendarApi.view; // Get the current view
      let start = view.activeStart; // Start date of the visible range
      let end = view.activeEnd; // End date of the visible range
      calendarApi.setOption('firstDay', this.locale.first_day_of_week);
      if(this.locale.country === 'GB') {
        calendarApi.setOption('locale', gbLocale);
        calendarApi.setOption('slotLabelFormat', function (date) {
          let hour = date.date.hour
          let minute = '00'
          let dd = 'AM'
          if (date.date.minute == 0){
            return hour + ':' + minute
          }}
        );
      }

      start = start.getFullYear() + '-' + ('0' + (start.getMonth()+1)).slice(-2) + '-' + ('0' + start.getDate()).slice(-2)
      end = end.getFullYear() + '-' + ('0' + (end.getMonth()+1)).slice(-2) + '-' + ('0' + end.getDate()).slice(-2)
      // let today = dt.getFullYear() + '-' + ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)

      let url = API_LOCATION + 'games/'
        + this.selectedGame.game_id
        + '/bookings?from=' + start
        + '&to=' + end
        + '&time=' + dt.getHours() + ':' + dt.getMinutes()
      let start_date = new Date()
      let end_date = new Date()
      let newEvent = {}

      axios.get(url)
        .then(response => {
          this.loading = false
          calendarApi.removeAllEvents();
          this.booking_slots = response.data.booking_slots
          this.min_period = response.data.min_period
          let blocked_days = 0
          let blocked_minutes = 0
          let unblock_date = new Date()

          // unblock_date.setDate(unblock_date.getDate() - 1)
          if(this.min_period.min_booking_period === 0){
            blocked_minutes = this.min_period.min_booking_value
            unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
          }else if(this.min_period.min_booking_period === 1){
            blocked_minutes = parseInt(this.min_period.min_booking_value) * 60
            unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
          } else if(this.min_period.min_booking_period === 2){
            blocked_days = this.min_period.min_booking_value
            unblock_date.setDate(unblock_date.getDate() + blocked_days)
          } else if (this.min_period.min_booking_period === 3){
            blocked_days = 7 * parseInt(this.min_period.min_booking_value)
            unblock_date.setDate(unblock_date.getDate() + blocked_days)
          } else if (this.min_period.min_booking_period === 4){
            //months
            const currentDate = new Date(); // Get the current date
            const futureDate = new Date(currentDate.getTime());

            futureDate.setMonth(futureDate.getMonth() + parseInt(this.min_period.min_booking_value));
            const timeDifference = futureDate.getTime() - currentDate.getTime();
            blocked_days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            unblock_date.setDate(unblock_date.getDate() + blocked_days)
          }

          if(response.data.booking_slots.length >0){
            calendarApi.setOption('slotMinTime', response.data.booking_slots[0].start_time);
          }
          for (let n in response.data.booking_slots){
            start_date = new Date()
            start_date = new Date(start_date.getFullYear(), start_date.getMonth(), 1)
            start_date.setFullYear(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[0])
            start_date.setMonth(parseInt(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[1]) - 1)
            start_date.setDate(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[2])
            start_date.setHours(response.data.booking_slots[n].start_time.split(':')[0]);
            start_date.setMinutes(response.data.booking_slots[n].start_time.split(':')[1]);
            end_date = new Date()
            end_date = new Date(end_date.getFullYear(), end_date.getMonth(), 1)
            end_date.setFullYear(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[0])
            end_date.setMonth(parseInt(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[1]) - 1)
            end_date.setDate(response.data.booking_slots[n].booking_date.split('T')[0].split('-')[2])
            end_date.setHours(response.data.booking_slots[n].end_time.split(':')[0]);
            end_date.setMinutes(response.data.booking_slots[n].end_time.split(':')[1]);

            //min max ranges
            let blocked_days = 0
            let blocked_minutes = 0
            let unblock_date = new Date()
            if(response.data.booking_slots[n].min_booking_period === 0){
              blocked_minutes = response.data.booking_slots[n].min_booking_value
              unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
            }else if(response.data.booking_slots[n].min_booking_period === 1){
              blocked_minutes = parseInt(response.data.booking_slots[n].min_booking_value) * 60
              unblock_date.setMinutes(unblock_date.getMinutes() + blocked_minutes)
            } else if(response.data.booking_slots[n].min_booking_period === 2){
              blocked_days = response.data.booking_slots[n].min_booking_value
              unblock_date.setDate(unblock_date.getDate() + blocked_days)
            } else if (response.data.booking_slots[n].min_booking_period === 3){
              blocked_days = 7 * parseInt(response.data.booking_slots[n].min_booking_value)
              unblock_date.setDate(unblock_date.getDate() + blocked_days)
            } else if (response.data.booking_slots[n].min_booking_period === 4){
              //months
              const currentDate = new Date(); // Get the current date
              const futureDate = new Date(currentDate.getTime());

              futureDate.setMonth(futureDate.getMonth() + parseInt(response.data.booking_slots[n].min_booking_value));
              const timeDifference = futureDate.getTime() - currentDate.getTime();
              blocked_days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
              unblock_date.setDate(unblock_date.getDate() + blocked_days)
            }

            let max_days = 0
            if(response.data.booking_slots[n].max_booking_period === 0){
              max_days = parseInt(response.data.booking_slots[n].max_booking_value)
            } else if (response.data.booking_slots[n].max_booking_period === 1){
              max_days = parseInt(response.data.booking_slots[n].max_booking_value) * 7
            } else if (response.data.booking_slots[n].max_booking_period === 2){
              let future = new Date()
              let today = new Date()
              future.setMonth(future.getMonth() + parseInt(response.data.booking_slots[n].max_booking_value))
              let diffTime = Math.abs(future - today)
              max_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            } else if (response.data.booking_slots[n].max_booking_period === 3){
              let future = new Date()
              let today = new Date()
              future.setFullYear(future.getFullYear() + parseInt(response.data.booking_slots[n].max_booking_value))
              let diffTime = Math.abs(future - today)
              max_days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            } else if (response.data.booking_slots[n].max_booking_period === null){
              max_days = 10
            }

            let max_date = new Date()
            max_date.setDate(max_date.getDate() + max_days)

            newEvent = {
              id: response.data.booking_slots[n].id,
              title: response.data.booking_slots[n].status,
              start: start_date,
              end: end_date,
              allDay: false
            }
            if((response.data.booking_slots[n].status === 'available' || response.data.booking_slots[n].status === 'checked')
              && start_date > unblock_date && start_date < max_date){
              newEvent.title = this.$t('book.available')
              newEvent.bookingSlot = response.data.booking_slots[n]
              newEvent.backgroundColor = "#42b883"
              newEvent.editable = false
              newEvent.startEditable = false
              calendarApi.addEvent(newEvent);
            }
            else if(start_date >= new Date() && (
              ((response.data.booking_slots[n].status === 'available'
              || response.data.booking_slots[n].status === 'checked')
              && start_date <= unblock_date && start_date < max_date)
              || response.data.booking_slots[n].status === 'call_to_book')){
              if(response.data.booking_slots[n].slot_text){
                newEvent.title = response.data.booking_slots[n].slot_text
              } else if (response.data.booking_slots[n].status === 'call_to_book' &&
              !response.data.booking_slots[n].has_external_link && !response.data.booking_slots[n].slot_text
              && !(response.data.booking_slots.status === 'booked' || response.data.booking_slots.status === 'blocked')){
                newEvent.title = this.bss.call_to_book_text
              }

              else {
                newEvent.title = this.$t('book.callToBook')
              }

              if(response.data.booking_slots[n].has_external_link){
                newEvent.url = response.data.booking_slots[n].external_link
              }
              newEvent.backgroundColor = "#246246"
              newEvent.editable = false
              newEvent.startEditable = false
              calendarApi.addEvent(newEvent);
            }
            else if ((response.data.booking_slots[n].status === 'booked') && start_date < max_date){
              newEvent.title = this.bss.booked_text
              newEvent.backgroundColor = "#fd5d93"
              newEvent.editable = false
              newEvent.startEditable = false
              calendarApi.addEvent(newEvent);
            }
            else if ((response.data.booking_slots[n].status === 'blocked') && start_date < max_date){
              newEvent.title = this.bss.blocked_text
              newEvent.backgroundColor = "#fd5d93"
              newEvent.editable = false
              newEvent.startEditable = false
              calendarApi.addEvent(newEvent);
            }

          }
          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    getMaxPlayersByCategory(arr) {
      const maxPlayersByCategory = {};

      // Iterate through the array to find the maximum players for each category
      arr.forEach((item) => {
        const { category_id, max_players } = item;

        if (!(category_id in maxPlayersByCategory) || max_players > maxPlayersByCategory[category_id]) {
          maxPlayersByCategory[category_id] = max_players;
        }
      });

      // Update each item in the array with the maximum players for its category
      const updatedArray = arr.map((item) => ({
        ...item,
        maximum_players_for_category: maxPlayersByCategory[item.category_id]
      }));

      return updatedArray;
    },
    async getPricing(){

      this.loading = true
      let url = API_LOCATION + 'games/' + this.selectedGame.game_id + '/pricing?booking_slot_id=' + this.selectedBooking.id
      axios.get(url)
        .then(response => {
          this.loading = false
          this.pricing = response.data.pricing
          this.price_settings = response.data.settings
          this.number_of_players = 0
          this.pricing_categories = []

          this.pricing = this.getMaxPlayersByCategory(this.pricing)
          for(let n in this.pricing){
            if(!(_.find(this.pricing_categories, { category_id: this.pricing[n].category_id }))){
              this.pricing_categories.push({
                category_id: this.pricing[n].category_id,
                category: this.pricing[n].category.toLowerCase(),
                number_of_players: this.pricing[n].default_participants,
                price: this.pricing[n].price,
                minimum_participants: this.pricing[n].minimum_participants,
                maximum_players_for_category: this.pricing[n].maximum_players_for_category
              })
              this.number_of_players++
            }
          }
          this.getSubtotal()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
      // this.$refs['step3'].loadModel(this.wizardModel)
    },
    async wizardComplete() {
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => {
              console.log(err)
          })
      })
    },
    async getSchedules(){
      let gameId = this.$route.params.event
      this.max_time_slots = 0
      if(this.show_calendar){
        setTimeout(()=>{
          let calendarApi = this.$refs.calendar.getApi();
          calendarApi.gotoDate(this.currentDay);
        }, 100);

      }
      let dt = new Date()
      dt = dt.getFullYear() + '-' + ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)

      if(dt !== this.currentDay){
        this.currentTime = '00:01'
      } else {
        let date = new Date()
        this.currentTime = date.getHours() + ':' + date.getMinutes()
      }

      let code = this.$route.params.company
      this.loading = true
      let url = API_LOCATION + 'bookings/company-groups/' + code + '?date=' + this.currentDay + '&time=' + this.currentTime

      if(gameId) {
        url += '&game=' + gameId
      }

      axios.get(url)
        .then(response => {
          this.loading = false
          this.games = _.orderBy(response.data.schedules, 'position')
          if(this.games.length <= 0 && gameId){
            this.notFound = true
          }
          if(this.games.length <= 0 ){
            this.empty = true
          }
          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          }
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })

    },
    roundToFixed(num, decimals) {
      let factor = Math.pow(10, decimals);
      return (Math.round(num * factor) / factor).toFixed(decimals);
    },
    getAmount(integer){
      if(integer == null){
        return
      }
      return this.roundToFixed(integer, 2)
    },
    validQuantity(){
      let num = 0
      let errors = 0
      for(let m in this.pricing_categories){
        num += this.pricing_categories[m].number_of_players
      }

      if(num > this.selectedGame.max_players_count){
        this.errors.number_of_players = this.$t('book.errors.numberOfPlayerExceedsCapacity')
        errors++
      } else if(num < this.selectedGame.min_players_count){
        this.errors.number_of_players = this.$t('book.errors.numberOfPlayerLessThanMinimum')
        errors++
      }
      else {
        this.errors.number_of_players = ''
      }
      return errors > 0 ? false : true

    },
    getSubtotal(){
      this.subtotal = 0
      this.number_of_players = 0
      if(this.price_settings.cumulative_category_pricing){
        if(this.price_settings.pricing_type_id === 1){
          for(let m in this.pricing_categories) {
            this.number_of_players += this.pricing_categories[m].number_of_players
          }
          for(let m in this.pricing_categories){
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.number_of_players >= this.pricing[n].min_players
                && this.number_of_players <= this.pricing[n].max_players) {
                this.subtotal += this.pricing[n].price * this.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.number_of_players > this.pricing[last_index].max_players){
              this.subtotal += this.pricing[last_index].price * this.pricing_categories[m].number_of_players
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.subtotal += smallest_price_category.price * this.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.price_settings.pricing_type_id === 2){
          for(let m in this.pricing_categories) {
            this.number_of_players += this.pricing_categories[m].number_of_players
          }
          for(let m in this.pricing_categories){
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.number_of_players >= this.pricing[n].min_players
                && this.number_of_players <= this.pricing[n].max_players) {
                this.subtotal += this.pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.number_of_players > this.pricing[last_index].max_players){
              this.subtotal += this.pricing[last_index].price
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.number_of_players !== 0 &&
              this.number_of_players < smallest_price_category.min_players){
              this.subtotal += smallest_price_category.price
            }
          }
        }
      } else {
        if(this.price_settings.pricing_type_id === 1){
          for(let m in this.pricing_categories){

            this.number_of_players += this.pricing_categories[m].number_of_players
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.pricing_categories[m].number_of_players >= this.pricing[n].min_players
                && this.pricing_categories[m].number_of_players <= this.pricing[n].max_players) {
                this.subtotal += this.pricing[n].price * this.pricing_categories[m].number_of_players
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players > this.pricing[last_index].max_players){
              this.subtotal += this.pricing[last_index].price * this.pricing_categories[m].number_of_players
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players !== 0 &&
              this.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.subtotal += smallest_price_category.price * this.pricing_categories[m].number_of_players
            }
          }
        }
        else if(this.price_settings.pricing_type_id === 2){
          for(let m in this.pricing_categories){
            this.number_of_players += this.pricing_categories[m].number_of_players
            for(let n in this.pricing){
              if(this.pricing[n].category_id === this.pricing_categories[m].category_id
                && this.pricing_categories[m].number_of_players >= this.pricing[n].min_players
                && this.pricing_categories[m].number_of_players <= this.pricing[n].max_players) {
                this.subtotal += this.pricing[n].price
                break
              }
            }
            let last_index = _.findLastKey(this.pricing, {category_id: this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players > this.pricing[last_index].max_players){
              this.subtotal += this.pricing[last_index].price
            }

            let smallest_price_category = _.find(this.pricing, {category_id : this.pricing_categories[m].category_id})
            if(this.pricing_categories[m].number_of_players !== 0 &&
              this.pricing_categories[m].number_of_players < smallest_price_category.min_players){
              this.subtotal += smallest_price_category.price
            }
          }
        }
      }
    },
    getBss(){
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/booking'

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.bss = response.data.config[0]
          this.locale = response.data.locale
          this.taxes = response.data.taxes
          this.fees = response.data.fees
          this.allow_pay_on_arrival = this.bss.allow_pay_on_arrival
          this.payment_settings = response.data.payment_settings
          if(this.payment_settings.allow_custom_tipping_options && this.payment_settings.custom_tipping_value_type === 1){
            this.tip_placeholder = this.$t('book.tipAmount') + ' (' + this.getCurrencySymbol(this.locale.currency) + ')'
          }

          this.tipping_options = response.data.tipping_options
          if(this.payment_settings.allow_custom_tipping_options && this.payment_settings.allow_preset_tipping_options){
            this.tipping_options.push({id: 0, name: this.$t('book.customAmount'), value_type: 0, amount: 0})
          }
          if((this.payment_settings.gift_cards_enabled && !this.$route.params.event) ||
            this.$route.params.event === 'gift-card' && this.payment_settings.gift_card_unique_link_enabled){
            this.is_gift_card = true
          } else if (this.$route.params.event === 'gift-card' && !this.payment_settings.gift_card_unique_link_enabled){
            this.notFound = true
          }
          this.payment_methods = response.data.payment_methods
          console.log(this.payment_methods.length)
          for(let n in this.payment_methods){
            if(this.payment_methods[n].name === 'Card'){
              this.payment_methods[n].display_name = this.$t('book.card')
            }
            else if(this.payment_methods[n].name === 'Gift card'){
              this.payment_methods[n].display_name = this.$tc('book.giftCard', 1)
            }
            else {
              this.payment_methods[n].display_name = this.payment_methods[n].name
            }
          }

          this.selectedPaymentOption = this.payment_methods[0]
          this.custom_fields = response.data.custom_fields
          this.custom_fields = _.orderBy(this.custom_fields, 'position')
          for(let n in this.custom_fields){
            if(this.custom_fields[n].type === 3){
              this.custom_fields[n].dropdown_options = _.orderBy(JSON.parse(this.custom_fields[n].dropdown_options), 'id')
            }
          }
          if(this.locale.show_day_of_week){
            this.day_of_week = 'ddd, '
          }
          if(this.locale.google_tag_active && this.locale.google_tag_container_id){
            this.initializeGtm(this.locale.google_tag_container_id)
          }
          if(this.locale.pixel_active && this.locale.pixel_id){
            this.addMetaPixel(this.locale.pixel_id)
          }
          if(this.locale.ga4_active && this.locale.ga4_id){
            this.insertGoogleAnalyticsScript(this.locale.ga4_id)
          }

          if(this.payment_settings.payment_gateway_id === 4){
            this.loadPaystack()
          }
          //Stripe
          if(this.payment_settings.payment_gateway_id === 3){
            this.loadStripe()
          }
          if(this.payment_settings.payment_gateway_id === 2){
            this.squareAppId = this.payment_settings.api_name
            this.squareLocationId = this.payment_settings.api_location
            this.loadSquare(this.payment_settings.is_live)
          }
          //Locale
          if(this.locale.country === 'GB'){
            this.phone_placeholder = '07700 900000'
          }
          //Fix year for el date picker
          this.locale.date_format = this.locale.date_format.replaceAll('Y', 'y')
          this.locale.date_format = this.locale.date_format.replaceAll('D', 'd')
        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    },
    async loadSquare(is_live){
      const script = document.createElement('script');
      if(is_live){
        script.src = 'https://web.squarecdn.com/v1/square.js'
      } else {
        script.src = 'https://sandbox.web.squarecdn.com/v1/square.js'
      }
      script.async = true;
      document.body.appendChild(script);
    },
    async payWithPaystack(bookings, custom_fields, gift_cards, subtotal) {
      let amount = this.due_now
      for(let n in this.applied_gift_cards){
        amount -= this.applied_gift_cards[n].remaining
      }
      let handler = PaystackPop.setup({
        key: this.payment_settings.api_name, // Replace with your public key
        email: this.user.email,
        amount: (parseFloat(amount) * 100).toFixed(0),
        ref: this.paystack_reference, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        callback: (response) => {
          this.handlePaystackPayment(response.reference, 'payment_succeeded')
        },
        onClose: () => {
          this.disable_submit = false
          this.handlePaystackPayment(this.paystack_reference, 'payment_failed')
        },
      });

      handler.openIframe()
      let offset = 0

      if(this.bss.clear_stale_carts){
        //5.Get time offset
        if(this.bss.cart_timeout_period === 0){
          offset = this.bss.cart_timeout_value * 60 * 1000
        } else if(this.bss.cart_timeout_period === 1){
          offset = this.bss.cart_timeout_value * 60 * 60 * 1000
        } else if(this.bss.cart_timeout_period === 2){
          offset = this.bss.cart_timeout_value * 24 * 60 * 60 * 1000
        }
        setTimeout(function() {
          window.location.reload();
        }, offset);
      }

    },
    async handlePaystackPayment(reference, status){
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/payment/paystack'
      let data = {
        status: status,
        paystack_reference: reference,
      }
      this.loading = true
      axios.post(url, data)
        .then(response => {
          if(window.fbq){
            fbq('track', 'Purchase', {value: this.due_now, currency: this.locale.currency});
          }
          if(window.gtag) {
            if (window !== window.top) {
              let eventData = {
                eventType: 'purchase',
                eventValue: this.due_now,
                currency: this.locale.currency
              }
              this.$nextTick(() => {
                this.postMessageTrackingEvent(eventData)
              });
            } else {
              gtag("event", "purchase", {
                currency: this.locale.currency,
                value: this.due_now
              })
            }
          }
          this.loading = false
          this.prevent_submit = true
          this.disable_submit = false
          this.order_number = response.data.orderId
          this.hash = response.data.hash
          this.timestamp = response.data.timestamp
          this.addToCart(9)
          if(window.fbq){
            fbq('track', 'Success', {value: this.due_now, currency: this.locale.currency});
          }
          if(window.gtag) {
            if (window !== window.top) {
              let eventData = {
                eventType: 'success',
                eventValue: this.due_now,
                currency: this.locale.currency
              }
              this.$nextTick(() => {
                this.postMessageTrackingEvent(eventData)
              });
            } else {
              gtag("event", "success", {
                currency: this.locale.currency,
                value: this.due_now
              })
            }
          }
          if(this.bss.auto_redirect){
            let time = parseInt(this.bss.redirect_delay) * 1000
            setTimeout(() => {
              window.location.href = this.bss.redirect_url
            }, time)
          }
          swal({
            title: this.$t('book.swals.congratulations'),
            text:  this.$t('book.swals.confirmedBooking'),
            type: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK'
          }).then((response)=> {
            this.$refs['wiz'].disableTabs();
            this.$refs['wiz'].tabIndex(4)
            if (window !== window.top) {
              this.$nextTick(() => {
                this.updateParentAboutHeightChange()
              });
            }
            this.scrollToTop()
          })
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
        })
    },
    async loadPaystack(){
      const script = document.createElement('script');
      script.src = 'https://js.paystack.co/v2/inline.js'
      script.async = true;
      document.body.appendChild(script);
    },
    async loadStripe(){
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      document.body.appendChild(script);
    },
    setCurrentDate(){
      let current = new Date()
      this.currentDay = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2)
      this.currentTime = current.getHours() + ':' + current.getMinutes()
    },
    updateParentAboutHeightChange() {
      const height = this.$el.scrollHeight; //
      window.parent.postMessage({action: 'updateHeight', height: height + 20}, '*');
    },
    postMessageTrackingEvent(event_data){
      window.parent.postMessage(event_data, '*');
    },
    handleBeforeUnload(event) {
      let code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/cart?hash=' + this.cart_id
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        keepalive: true  // This is crucial for sending data on unload
      });
    }
  },
  beforeDestroy(){
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    if(this.isMobile){
      this.calendarOptions.initialView = 'timeGridDay'
    }
    this.setCurrentDate()
    this.getBss()
    this.createCart()

    if(this.$route.params.event === 'gift-card'){
      this.gift_card_link = true
    }
    this.getSchedules()
  }
}
</script>

<style scoped>
.button-wide{
  width: 95%;
}
.no-upper-margin{
  margin-top: -15px;
}
.button-slot{
  width: 99%;
}
.days-width{
  width: 10%;
}
.fixed-size-img {
  width: 100%; /* width of container */
  height: 250px; /* height of container */
  object-fit: cover;
}
.subtext{
  font-size: x-small;
}

.game-img{
  display: inline-flex;
  align-items: flex-end;
  justify-content: right;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.address-overlay{
  margin-top: -70px;
  margin-bottom: 30px;
}

.empty-bookings{
  text-align: center;
  padding: -10px 5px -10px;
  border: 1px solid #e44cc4;
  border-radius: .1875rem;
  /*margin: 0px 0 -10px;*/
  min-height: 90px;
}
.empty-bookings i{
  font-size: 1em;
  margin: 0px 0 -10px;
}

.empty-bookings p{
  padding: 7px;
  /*margin-top: 30px;*/
  font-size: 0.8em;
}
.el-date-editor.el-input .el-input__inner {
  cursor: crosshair !important;
}

</style>
